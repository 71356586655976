














































































































































import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route} from "vue-router";
import assistanceStore from "./assistance.store";
import {ROUTES} from "../../assistance.page";
import {AssistanceType} from "../assistance-type.enum";


declare var staticUrl: string;

// data: AssistanceChoiceBoxData
@Component({
    props: {
        data: Object
    }
})
export default class AssistanceChoiceBoxComponent extends Vue {
    public $router: VueRouter;
    public $route: Route;
    public staticUrl = staticUrl;

    private shouldDisplayInfoLabel: boolean = false;

    created(){
        this.shouldDisplayInfoLabel = !!this.$props.data.infoLabel;
    }

    boxButtonPressed() {
        switch (this.$props.data.type) {
            case AssistanceType.DIAGNOSTIC: {
                assistanceStore.setAssistanceType(AssistanceType.DIAGNOSTIC);
                this.$router.push({path: ROUTES.TIMESLOT, query: Object.assign({}, this.$route.query, {assistanceType: assistanceStore.assistanceType})})
                break;
            }
            case AssistanceType.TECHNICAL: {
                this.$emit('showPreRequisitModal');
                break;
            }
            default:

        }

    }
}
