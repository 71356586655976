export class PhoneNumberValidator {

    public validate(phoneNumber: string) {
        if (!phoneNumber) {
            return false;
        }

        if (phoneNumber.startsWith("+")){
            return phoneNumber.length <= 15 && phoneNumber.length >= 10 && /^\+[0-9]+$/.test(phoneNumber);
        } else {
            return phoneNumber.length >= 10  && /^[0-9]+$/.test(phoneNumber);
        }
    }

}

export default new PhoneNumberValidator();
