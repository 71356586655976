import Page from "../common/models/page";
import apiClient from "../common/services/api.client";
import Account from "./account";
import Address from "./address";
import ChangePasswordData from "./change-password-data";
import Order from "./order.model";
import OrderReturnRequest from "./order-return-request";
import {AccountAssistanceRdv} from "./assistance/assistance-rdv.model";
import {AccountRescheduleAssistanceRequest} from "./account-reschedule-assistance-request";

/**
 * This service aims to handle all informations linked to the account of the current user.
 */
export class AccountService {

  // Account

  public async getAccount() {
    const account = await apiClient.get<Account>("/account");
    return this.transformAccountResponse(account);
  }

  public async updateAccount(account: Account) {
    const savedAccount = await apiClient.put<Account, Account>("/account", account);
    return this.transformAccountResponse(savedAccount);
  }

  public async updatePassword(changePasswordData: ChangePasswordData) {
    return apiClient.put<void, ChangePasswordData>("/account/change-password", changePasswordData);
  }

  // Addresses

  public async getAddresses() {
    return apiClient.get<Address[]>("/account/addresses");
  }

  public async getAddress(id: string) {
    return apiClient.get<Address>(`/account/addresses/${id}`);
  }

  public async addAddress(address: Address) {
    return apiClient.post<Address, Address>("/account/addresses", address);
  }

  public async updateAddress(address: Address) {
    if (!address.id) {
      throw new Error("Unable to update an address without an id.");
    }
    return apiClient.put<Address, Address>("/account/addresses", address);
  }

  // Orders

  public async getOrders(page: number = 0, size: number = 10): Promise<Page<Order>> {
    return apiClient.get<Page<Order>>(`/account/orders?page=${page}&size=${size}&sort=createdDate,desc`);
  }

  public async getOrder(id: string): Promise<Order> {
    const order = await apiClient.get<Order>(`/account/orders/${id}`);
    return this.transformOrderResponse(order);
  }

  private transformOrderResponse(order: Order) {
    for (const pack of order.packages) {
      if (typeof pack.updatedDate === "string") {
        pack.updatedDate = new Date(pack.updatedDate);
      }
    }
    return order;
  }

  public async sendOrderReturnRequest(returnRequest: OrderReturnRequest): Promise<void> {
    return apiClient.post<void, OrderReturnRequest>(`/account/orders/return`, returnRequest);
  }

  private transformAccountResponse(account: Account) {
    if (typeof account.birthDate === "string") {
      account.birthDate = new Date(account.birthDate);
    }
    return account;
  }

  // GDPR
  public async sendGdprRequest(): Promise<void> {
    return apiClient.get<void>(`/account/gdpr-remove-personal-data`);
  }

  // ASSISTANCES
  public async getAssistances(page: number = 0, size: number = 10): Promise<Page<AccountAssistanceRdv>> {
    return apiClient.get<Page<AccountAssistanceRdv>>(`/account/assistances?page=${page}&size=${size}&sort=updateDate,desc`);
  }

  public async cancelAssitance(assistance: AccountAssistanceRdv ): Promise<AccountAssistanceRdv> {
    return apiClient.delete<AccountAssistanceRdv>(`/account/assistances/${assistance.assistanceRdv.id}`);
  }

  public async rescheduleAssitance(dto: AccountRescheduleAssistanceRequest): Promise<AccountAssistanceRdv> {
    return apiClient.put<AccountAssistanceRdv, AccountRescheduleAssistanceRequest>(`/account/assistances/${dto.assistanceId}`, dto);
  }

  public async subscribeNewsletter(emailAddress: string): Promise<void> {
    return apiClient.post<void, any>(`/newsletter`, {emailAddress});
  }

  public async getAssistance(assistanceId: string): Promise<AccountAssistanceRdv>{
    return apiClient.get<any>(`/assistance/${assistanceId}`);
  }
}

export default new AccountService();
