
































import Vue from "vue";
import Component from "vue-class-component";
import accountService from "../account.service";
import Page from "../../common/models/page";
import {ROUTES} from "../account.page";
import PagerComponent from "../../common/ui/pager.component.vue";
import {Route} from "vue-router";
import AlertComponent from "../../common/ui/alert.component.vue";
import {AlertType} from "../../common/ui/alert-type";
import {AccountAssistanceRdv} from "./assistance-rdv.model";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import {SimpleQueryStatus} from "../../common/models/simple-query-status";
import AssistanceCardComponent from "./assistance-card.component.vue";

@Component({
    components: {
        'assistance-card': AssistanceCardComponent,
        pager: PagerComponent,
        alert: AlertComponent,
        'spinner': SpinnerComponent,
    },
    computed: {
        assistancesCount: function () {
            return this.assistancePage?.content.length ?? 0;
        },
    },
    props: {
        context: Object,
        account: Object
    },
    watch: {
        "$route.query.page": async function (page) {
            await this.loadAssistances();
        }
    }
})
export default class AssistanceListComponent extends Vue {
    public $route: Route;
    public context: any;
    public pageQS: SimpleQueryStatus = {isError: false, isLoading: true};
    public $t: any
    public assistancePage: Page<AccountAssistanceRdv> = null;
    public linkBase = ROUTES.ASSISTANCE_LIST;
    public AlertType = AlertType;

    public created() {
        (async () => {
            await this.loadAssistances();
        })();

    }

    public async loadAssistances() {
        this.pageQS.isLoading = true;
        try {
            let page: number = parseInt(this.$route.query.page as string, 10);
            if (!Number.isInteger(page)) {
                page = 1;
            }
            this.assistancePage = await accountService.getAssistances(Math.max(page - 1, 0));
        } catch (e) {
            this.pageQS.isError = true;

        }
        this.pageQS.isLoading = false;
    }

    public async handleAssistanceChange(){
        await this.loadAssistances();
    }

}
