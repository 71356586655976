













































































































































import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import authenticationService from "../common/authentication/authentication.service";
import Address from "./address";
import { AddressType } from "./address-type";
import FieldComponent from "../common/ui/field.component.vue";
import { ROUTES } from "./account.page";
import VueRouter, { Route } from "vue-router";
import accountService from "./account.service";
import AlertComponent from "../common/ui/alert.component.vue";
import SpinnerComponent from "../common/ui/spinner.component.vue";
import zipCodeValidator from "../common/validators/zip-code.validator";
import orderService from "../order/order.service";
import dqeService, {
  DqeRnvpResult,
  DqeStatus
} from "../common/dqe/dqe.service";
import DqeRnvpComponent from "../common/dqe/dqe-rnvp.component.vue";
import {OriginDqeEnum} from "../common/dqe/origin-dqe.enum";

@Component({
  props: {
    context: Object
  },
  components: {
    "dqe-rnvp": DqeRnvpComponent,
    alert: AlertComponent,
    field: FieldComponent,
    spinner: SpinnerComponent
  },
  watch: {
    "$route.query.type": async function() {
      this.loadAddress();
    }
  },
  validations: {
    address: {
      firstName: {
        required,
        maxLength: maxLength(32)
      },
      lastName: {
        required,
        maxLength: maxLength(32)
      },
      companyName: {
        maxLength: maxLength(32)
      },
      countryCode: {
        required,
        maxLength: maxLength(32)
      },
      line1: {
        required,
        maxLength: maxLength(32)
      },
      line2: {
        maxLength: maxLength(32)
      },
      city: {
        required,
        maxLength: maxLength(32)
      },
      zipCode: {
        required,
        zipCode: function(value: string) {
          return zipCodeValidator.validate(value, this.address.countryCode);
        },
        maxLength: maxLength(32)
      },
      phoneNumber: {
        required: function() {
          if (this.withPhoneNumber) {
            return required;
          }
          return true;
        },
        maxLength: maxLength(32)
      }
    }
  }
} as any)
export default class EditAddressComponent extends mixins(validationMixin) {
  public ErrorCode = {
    Server: "server"
  };
  public address: Address = null;
  public countries: any[] = [];
  public isLoading = false;
  public submitting = false;
  public submitSuccess = false;
  public submitErrorCode: string = null;
  public context: any;
  public withPhoneNumber: Boolean = false;
  public verificationPending = false;
  public dqeRnvpResult: DqeRnvpResult = null;
  public $v: any;
  OriginDqeEnum = OriginDqeEnum;

  public created() {
    this.context.isLoading = true;
    (async () => {
      await this.loadAddress();
      this.countries = await orderService.getCountries();
      this.context.isLoading = false;
    })();
  }

  public async loadAddress() {
    this.isLoading = true;
    const route: Route = (this as any).$route;
    const router: VueRouter = (this as any).$router;
    if (route.query && route.query.type && !route.params.id) {
      let address: Partial<Address> = {
        firstName: null,
        lastName: null,
        line1: null,
        line2: null,
        city: null,
        countryCode: null,
        zipCode: null,
        companyName: null,
        default: true
      };
      if (route.query.type == "billing") {
        address.type = AddressType.Billing;
      } else if (route.query.type == "shipping") {
        address.type = AddressType.Shipping;
      }
      this.address = address as Address;
    } else if (route.params && route.params.id) {
      this.address = await accountService.getAddress(route.params.id);
    }

    if (this.address === null) {
      router.push(ROUTES.HOME);
    }

    this.withPhoneNumber = this.address.type == AddressType.Billing;
    this.isLoading = false;
  }

  public async updateAddress() {
    this.submitting = true;
    this.submitSuccess = false;
    this.submitErrorCode = null;
    if (this.address.id) {
      try {
        await accountService.updateAddress(this.address);
        this.submitSuccess = true;
      } catch (error) {
        this.submitErrorCode = this.ErrorCode.Server;
      }
    } else {
      try {
        await accountService.addAddress(this.address);
        this.submitSuccess = true;
      } catch (error) {
        this.submitErrorCode = this.ErrorCode.Server;
      }
    }
    this.submitting = false;
  }

  public getErrorMessageKey(validation: any) {
    if (validation.zipCode === false) {
      return "common.error.field-invalid-zip-code";
    } else if (validation.maxLength === false) {
      return "common.error.field-max-length";
    }
    return "common.error.field-required";
  }

  public async verifyAddress() {
    if (!this.$v.$invalid) {
      this.verificationPending = true;
      this.$emit("is-invalid", true);
      this.dqeRnvpResult = await dqeService.checkAddress(this.address);
      if (this.dqeRnvpResult.status === DqeStatus.Warning) {
        this.applyCorrections(this.dqeRnvpResult.corrections);
      }
      this.$emit(
        "is-invalid",
        this.dqeRnvpResult.status !== DqeStatus.Success &&
          this.dqeRnvpResult.status !== DqeStatus.Warning
      );
      this.verificationPending = false;
    }
  }

  public applyCorrections(corrections: Address) {
    for (let key in corrections) {
      (this.address as any)[key] = (corrections as any)[key];
    }
  }
}
