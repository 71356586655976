import { render, staticRenderFns } from "./order-preference.component.vue?vue&type=template&id=58f406a8&"
import script from "./order-preference.component.vue?vue&type=script&lang=ts&"
export * from "./order-preference.component.vue?vue&type=script&lang=ts&"
import style0 from "./order-preference.component.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "order-preference.component.vue"
export default component.exports