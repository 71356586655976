



















import Vue from "vue";
import Component from "vue-class-component";
import {Route} from "vue-router";
import TimeslotSelectionComponent from "../shared/timeslot-selection.component.vue";
import InterventionValidateTimeslotComponent from "./intervention-validate-timeslot.component.vue";
import interventionStore from "./intervention.store";
import {TimeslotEvent} from "../../../common/models/timeslot-event.model";


enum Step {
    Selection,
    Validate
}
@Component({
    components:{
        'timeslot-selection': TimeslotSelectionComponent,
        'intervention-validate-timeslot': InterventionValidateTimeslotComponent
    }
})
export default class InterventionTimeslotSelectionComponent extends Vue {
    public $route: Route;
    public reference: string;
    public interventionType: string = "INTER3";
    Step = Step;
    step = Step.Selection;

    created(){
    }

    public cancel() {
        this.$emit('back');
    }

    public confirm(event: TimeslotEvent){
        interventionStore.setSelectedTimeslot(event.selectedTimeSlot);
        interventionStore.setSuggestedTimeSlots(event.suggestedTimeSlots);
        this.step = Step.Validate;
    }
}
