export default class AutoCompleteReferenceReply {

  public reference: string;
  public commercialReference: string;
  public technicalReference: string;
  public link: string;
  public brand: string;
  public label: string;
  public selected: boolean;

}
