import apiClient from "../services/api.client";
import reCaptchaService from "../services/re-captcha.service";

declare var brand: string;
export class SupportRequestsService {

    public async requestACallBack(action: string, phoneNumber: string) {

        const reCaptchaResponse = await reCaptchaService.getSiteToken(action);
        brand = !!brand ? brand : "BRANDT";
        const contactRequest = {
            phone: phoneNumber,
            brand,
            reCaptchaResponse
        };
        return apiClient.post(`/support-requests`, contactRequest);
    }

    // feature removed
    // public async staWarrantySupportRequest(action: string, phoneNumber: string, reference: string, postalCode: string) {
    //     const staWarrantySupportRequest = {
    //         zipCode: postalCode,
    //         phone: phoneNumber,
    //         product: reference
    //     };
    //     return apiClient.post(`/sta-warranty-support-request`, staWarrantySupportRequest);
    // }

}

export default new SupportRequestsService();
