
import Component, {mixins} from "vue-class-component";
import BrandtVue from "../../common/brandt-vue";
import CartDetailMixin from "../../cart/cart-detail.mixin";
import CartMixin from "../../cart/cart.mixin";
import {Cart} from "../../cart/cart";
import UtilsService from "../../common/services/utils.service";
import {CartItem} from "../../cart/cart-item";
import VueRouter, {Route} from "vue-router";
import {PATHS} from "./order.page";
import orderStore from "./order.store";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import {ShippingCode} from "../../common/ui/shipping-code";

declare var staticUrl: string;
declare var item: any;
declare var cart: any;

// the name to display
function computeItemTitle(item: CartItem) {
    if(item){
        let itemTitle = item.product.title;
        if( item.assistanceRdv != undefined){
            return itemTitle + " - " + item.assistanceRdv.endProductReference + " - " + UtilsService.formatTimeslotDateTime(item.assistanceRdv.timeSlot);
        }
        return itemTitle;
    }
}

@Component({
    filters: {
        itemTitle: computeItemTitle
    },
    components: {
        spinner: SpinnerComponent,
    }
})
export default class SummaryRelayPointComponent extends mixins(BrandtVue, CartDetailMixin, CartMixin) {
    public staticUrl = staticUrl;
    public noImageSrc = staticUrl + "/pictures/no-image.png";
    public cart: Cart;
    public $router: VueRouter;
    public $route: Route;
    private state: any;
    public isLoading = true;

    async created() {
        this.state = orderStore.state;
        this.cart = this.state.cart;
        this.isLoading = false;
    }

    public redirectTo() {
        orderStore.setStepRelayPointToConfirmation();
        this.$router.push({path: `/${PATHS.STEP}/${PATHS.PREFERENCES}`, query: this.$route.query});
    }

    public selectedShippingMethod(): string{
        switch (this.cart.shippingCode) {
            case ShippingCode.STANDARD:
                return 'cart-summary.delivery-standard';
            case ShippingCode.RELAY_POINT_COLISSIMO:
                return 'cart-summary.delivery-relay-point';
            case ShippingCode.RELAY_POINT_COLISSIMO_CONSIGNE:
                return 'cart-summary.delivery-relay-point';
            default:
                return 'cart-summary.delivery-standard';
        }
    }

    public back() {
        this.$router.push({path: PATHS.RELAY_POINT, query: this.$route.query});
    }
}
