












































































































import Vue from "vue";
import Component from "vue-class-component";
import { Timeslot } from "../../../common/models/timeslot.model";
import UtilsService from "../../../common/services/utils.service";
import SpinnerComponent from "../../../common/ui/spinner.component.vue";
import AlertComponent from "../../../common/ui/alert.component.vue";
import assistanceRdvService from "../../services/assistance-rdv.service";
import cartService from "../../../cart/cart.service";
import momentDateFormatService from "../../../common/momentDateFormat.service";

import _ from "lodash";
import {InterventionType} from "../intervention/intervention-type.enum";
import {FindInterventionTimeslots} from "../../models/find-intervention-timeslots";
import {Address} from "../../models/address";

import interventionStore from "../intervention/intervention.store";
import interventionRdvService from "../../services/intervention-rdv.service";
import accountService from "../../../account/account.service";
import {AccountAssistanceRdv} from "../../../account/assistance/assistance-rdv.model";
import {TimeslotEvent} from "../../../common/models/timeslot-event.model";
import {InterventionTimeslotsResults} from "../../models/intervention-timeslots-results";

@Component({
    components: {
        spinner: SpinnerComponent,
        alert: AlertComponent,
    },
    props: {
        type: String,
        reserve_error: {
            type: Boolean,
            default: false
        },
        assistanceId: String
    }
})
export default class TimeslotSelectionComponent extends Vue {

    MAX_ATTEMPT_INTER: number = 30;
    private timeslots: Timeslot[] = [];
    private timeslotsResultArray: Timeslot[] = [];
    private selectedTimeslot: Timeslot = null;

    private timeslotsChunksIndex: number = 0;
    private timeslotsChunkSize: number = 10;
    private timeslotsChunks: (Timeslot[])[] = [];
    private canDisplayMoreTimeslots: boolean = true;

    private submitting = false;
    private error: any | null = null;

    private intervention: boolean = false;
    private assistance: boolean = false;
    private badType: boolean = false;
    private firstSearch: boolean = true;
    private assistanceId: string;
    private searchAttempt: number = 0;
    private oldStartSearchDate: Date | string = null;


    created() {
        if (this.$props.assistanceId){
            this.assistanceId = this.$props.assistanceId;
        }

        if(this.$props.type === "FDIAG" || this.$props.type === "FASSIST"){
            this.assistance = true;
            this.loadAssistanceTimeslot(this.$props.type);
        }else if (this.$props.type in InterventionType){
            this.intervention = true;
            this.loadInterventionTimeslot(this.$props.type, null);
        }else{
            this.badType = true;
        }
    }

    async loadAssistanceTimeslot(type: String) {
        if (type === undefined || type === null){
            return;
        }

        this.submitting = true;
        var request = {
            type: type,
            cartId: cartService.getCartId()
        }
        try {
            var result = await assistanceRdvService.getAssistanceTimeSlots(request);
            this.timeslotsResultArray = _.map(result, slot => {
                slot.startTime = momentDateFormatService.ISO8601StringToMomentToString(String(slot.startTime));
                slot.endTime = momentDateFormatService.ISO8601StringToMomentToString(String(slot.endTime));
                return <Timeslot>slot;
            });
            this.sortTimeslots();
            this.timeslotsChunks = _.chunk(this.timeslotsResultArray, this.timeslotsChunkSize);
            this.timeslots = this.timeslotsChunks.length > 0 ? this.timeslotsChunks[this.timeslotsChunksIndex] : [];
            this.error = null;
        }catch (e){
            this.error = e;
        }
        this.submitting = false;
    }

    async loadInterventionTimeslot(type: String, startSearchDate: Date) {
        this.submitting = true;
        try {
            if(this.timeslots !== null && this.oldStartSearchDate !== null){
                /*
                * On récupère l'ancienne date de fin pour lancer la nouvelle recherche des interventions.
                 */

                // Initialise startSearchDate.
                if (typeof this.oldStartSearchDate === 'string') {
                    startSearchDate = new Date(this.oldStartSearchDate);
                } else {
                    startSearchDate = this.oldStartSearchDate;
                }

                // On ajoute 1 jour pour éviter les doublons dans la liste.
                if(startSearchDate !== null){
                    startSearchDate.setUTCDate(startSearchDate.getUTCDate() + 1);
                }
            }


            let result: InterventionTimeslotsResults = await interventionRdvService.getInterventionTimeSlots(await this.loadDto(startSearchDate));
            this.timeslotsResultArray = _.map(result.timeSlots, slot => {
                slot.startTime = momentDateFormatService.ISO8601StringToMomentToString(String(slot.startTime));
                slot.endTime = momentDateFormatService.ISO8601StringToMomentToString(String(slot.endTime));
                return <Timeslot>slot;
            });
            this.oldStartSearchDate = result.startSearchDate;

            // Disable by Brandt 29/04/24
            //this.sortTimeslots();

            if (this.timeslots !== null ){
                if (this.timeslotsResultArray.length > 0){

                    // add only new timeslots
                    const existingDays = new Set(this.timeslots.map(slot => new Date(slot.startTime).toDateString()));
                    const filteredTimeslots = this.timeslotsResultArray.filter(slot => {
                        const slotDay = new Date(slot.startTime).toDateString();
                        return !existingDays.has(slotDay);
                    });

                    this.timeslots = _.concat(this.timeslots, filteredTimeslots);
                } else {
                    this.canDisplayMoreTimeslots = true;
                }
            } else {
                if (this.timeslotsResultArray.length > 0){
                    this.timeslots = this.timeslotsResultArray;
                } else {
                    this.canDisplayMoreTimeslots = false;
                }
            }
            this.searchAttempt ++;

            if(this.searchAttempt > this.MAX_ATTEMPT_INTER){
                this.canDisplayMoreTimeslots = false;
            }

        }catch (e){
            this.error = e;

        }finally {
            this.submitting = false;
            this.firstSearch = false;
        }
    }

    loadMore() {
        if (this.timeslots.length % 10 != 0 || this.timeslots.length === 0 || this.timeslotsResultArray.length === this.timeslots.length ||
            this.searchAttempt > this.MAX_ATTEMPT_INTER){
            this.canDisplayMoreTimeslots = false;
            return;
        }
        this.timeslotsChunksIndex++;
        this.timeslots = _.concat(this.timeslots, this.timeslotsChunks[this.timeslotsChunksIndex])
    }

    public formatTimeslotAssistance(timeslot: Timeslot): string {
        return UtilsService.formatTimeslotDateTime(timeslot);
    }

    public formatTimeslotIntervention(timeslot: Timeslot): string {
        return UtilsService.formatTimeslotDateOnly(timeslot);
    }

    public cancel(){
        this.selectedTimeslot = null;
        this.$emit('cancel');
    }


    public confirm(){
        const eventData: TimeslotEvent = {
            selectedTimeSlot: this.selectedTimeslot,
            suggestedTimeSlots: this.timeslots
        };
        this.$emit('confirm', eventData);
    }

    private sortTimeslots(){
        this.timeslotsResultArray.sort((a, b) => {
            return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
        });
    }

    private async loadDto(searchStartDate: Date): Promise<FindInterventionTimeslots>{
        let reference: string; // reference pour laquelle on cherche un rdv, c'est important pour les inter à cause des planifications rules
        let dtoAddress:Address = null;

        if (interventionStore.addressIntervention) {
            dtoAddress = ({
                line1: interventionStore.addressIntervention.line1,
                line2: interventionStore.addressIntervention.line1,
                zipCode: interventionStore.addressIntervention.zipCode,
                city: interventionStore.addressIntervention.city,
                countryCode: interventionStore.addressIntervention.countryCode
            });
            reference = interventionStore.referenceUnit;
        } else {
            await (async () => {
                const assistance = await this.loadAssistance(this.assistanceId);
                dtoAddress = ({
                    line1: assistance.assistanceRdv.address.line1,
                    line2: assistance.assistanceRdv.address.line2,
                    zipCode: assistance.assistanceRdv.address.zipCode,
                    city: assistance.assistanceRdv.address.city,
                    countryCode: assistance.assistanceRdv.address.countryCode
                })
                reference = assistance.assistanceRdv.endProductId;
            })();
        }

        return ({searchStartDate: searchStartDate,
            address: dtoAddress,
            reference: reference
        });

    }


    public async loadAssistance(assistanceId: string) : Promise<AccountAssistanceRdv>{
        try {
            const assistance = await accountService.getAssistance(assistanceId);
            return assistance;
        } catch (err) {

        }
    }
}
