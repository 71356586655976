
import {Vue, Watch, Component} from 'vue-property-decorator';

import { PATHS } from './order.page';

import VueRouter from 'vue-router';

import {ColissimoResult} from "./colissimo.service";

import ColissimoService from "./colissimo.service";
import UtilsHttpService from "../../common/services/utils-http.service";
import GeocodingService from "../../common/services/geocoding/geocoding.service";
import orderStore from "./order.store";
import DeliveryErrorModal from "../../common/delivery-error-modal.component.vue";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import {ShippingCode} from "../../common/ui/shipping-code";
import {ColissimoPayloadWidget} from "../../common/services/geocoding/colissimo-payload-widget.model";
import AccountService from "../../account/account.service";

declare var cartLink: string;

@Component({
    components: {
        DeliveryErrorModal,
        'spinner': SpinnerComponent
    }
})
export default class RelayPointComponent extends Vue {

    token: string = "";
    urlServer: string = '';
    $router!: VueRouter;
    isLoading = true;
    state = orderStore.state;
    showModal = false;
    modalMessage = '';
    errorMessage = '';
    title = '';
    userLocation = {latitude: 0, longitude: 0};
    isInitailized = false;
    backBtnIsVisible = true;
    isMobile = false;


    async created() {
        this.checkMobileView();

        await this.getUserLocation();

        window.addEventListener('beforeunload', () => {
            (window as any).jQuery('#colissimoWidget').frameColissimoClose();
        });


        this.urlServer = await ColissimoService.getColissimoWidgetUrl();
        this.token = await ColissimoService.getColissimoToken();

        await this.loadAndInit();
        this.isInitailized = true;
    }

    mounted() {
        (window as any).selectAddressCallBack = this.selectAddressCallBack.bind(this);
    }

    async loadAndInit() {
        await this.loadExternalLibraries();
        this.isLoading = false;
        await this.initWidgetColissimo(this.userLocation);

    }

    private async selectAddressCallBack(data: ColissimoResult) {
        if(!this.shippingIsAvailable(data)){
            this.showModal = true;
            return;
        }

        this.backBtnIsVisible = false;
        this.isLoading = true;
        await orderStore.setAddressByColissimoResult(data);
        (window as any).jQuery('#colissimoWidget').frameColissimoClose();
        delete (window as any).selectAddressCallBack;
        await this.$router.push({path: PATHS.SUMMARY_RELAY_POINT});
    }

    async initWidgetColissimo(userLocation: any) {


        if(this.userLocation !== undefined && userLocation.latitude !== 0 && userLocation.longitude !== 0){

            const colissimoPayload: ColissimoPayloadWidget = await GeocodingService.getColissimoAddress(userLocation.longitude, userLocation.latitude);

            (window as any).jQuery('#colissimoWidget').frameColissimoOpen({
                "URLColissimo": this.urlServer,
                "callBackFrame": 'selectAddressCallBack',
                "ceCountry": "FR",
                "ceAddress": colissimoPayload.street,
                "ceZipCode": colissimoPayload.postcode,
                "ceTown": colissimoPayload.city,
                "origin": "WIDGET",
                "ceCountryList": ["FR"], // Enable france only
                "token": this.token
            });
        } else {

            const userAddresses = await AccountService.getAddresses();

            if(userAddresses != null && userAddresses.length > 0) {
                const userAddress = userAddresses[0];
                (window as any).jQuery('#colissimoWidget').frameColissimoOpen({
                    "URLColissimo": this.urlServer,
                    "callBackFrame": 'selectAddressCallBack',
                    "ceCountry": "FR",
                    "ceAddress": userAddress.line1,
                    "ceZipCode": userAddress.zipCode,
                    "ceTown": userAddress.city,
                    "origin": "WIDGET",
                    "ceCountryList": ["FR"], // Enable france only
                    "token": this.token
                });
            } else {
                (window as any).jQuery('#colissimoWidget').frameColissimoOpen({
                    "URLColissimo": this.urlServer,
                    "callBackFrame": 'selectAddressCallBack',
                    "ceCountry": "FR",
                    "ceAddress": "23 rue de rivoli",
                    "ceZipCode": "75004",
                    "ceTown": "Paris",
                    "origin": "WIDGET",
                    "ceCountryList": ["FR"], // Enable france only
                    "token": this.token
                });
            }
        }
    }

    async loadExternalLibraries() {
        try {
            await UtilsHttpService.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.js');
            await UtilsHttpService.loadScript('https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.js');
            await UtilsHttpService.loadScript('https://ws.colissimo.fr/widget-colissimo/js/jquery.plugin.colissimo.min.js');
            await UtilsHttpService.loadCSS('https://api.mapbox.com/mapbox-gl-js/v2.6.1/mapbox-gl.css');

        } catch (error) {

        }
    }

    close(){
        this.showModal = false;
    }

    shippingIsAvailable(data: ColissimoResult){
        const consigneStr = 'consigne';
        const availableShippingMethods = orderStore.getAvailableShippingsMethods();
        const selectedShippingMethod = orderStore.state.cart.shippingCode;
        const isConsigneSelected = data.nom.toLowerCase().includes(consigneStr);
        const consigneIsNotAvailable = availableShippingMethods.every(
            el => el.shippingCode !== ShippingCode.RELAY_POINT_COLISSIMO_CONSIGNE
        );

        if (isConsigneSelected && consigneIsNotAvailable) {
            // on est obligé de vérifier nous même le widget ne permet pas de trier... (13/12/2024)
            this.title = 'Consigne indisponible';
            this.modalMessage = 'Le type de relais pick-up "Consigne" n\'est pas disponible pour votre commande. Veuillez sélectionner un autre type de relais pick-up.';
            return false;
        }

        const shippingMethodIsAvailable = availableShippingMethods.some(
            el => el.shippingCode === selectedShippingMethod
        );

        if (!shippingMethodIsAvailable) {
            this.title = 'Erreur';
            this.modalMessage = 'Une erreur est survenue.';
            return false;
        }

        return true;
    }

    back() {
        console.log('cartLink', cartLink)
        window.location.href = cartLink;
    }


    getUserLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.userLocation = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                },
                (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            this.errorMessage = "Permission refusée pour accéder à la localisation.";
                            break;
                        case error.POSITION_UNAVAILABLE:
                            this.errorMessage = "Localisation non disponible.";
                            break;
                        case error.TIMEOUT:
                            this.errorMessage = "Le temps d'attente pour obtenir la localisation a expiré.";
                            break;
                        default:
                            this.errorMessage = "Une erreur inconnue s'est produite.";
                    }
                    console.warn(this.errorMessage);
                }
            );
        } else {
            this.errorMessage = "La géolocalisation n'est pas supportée par ce navigateur.";

        }
    }

    checkMobileView() {
        this.isMobile = window.innerWidth < 800;
    }



    @Watch('userLocation', { immediate: true, deep: true })
    onUserLocationChanged(newLocation: { latitude: number; longitude: number }) {

        if (newLocation.latitude !== 0 && newLocation.longitude !== 0 && this.isInitailized) {
            this.loadAndInit();
        }
    }

}
