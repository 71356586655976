import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";

import "../common/aside-cta/aside-with-cta.scss";
import PhotoModalComponent from "../common/ui/photo-modal.component.vue";
import CompatibilityChecker from "../compatibility-check/compatibility-checker.vue";
import NetReviewsProductReviews from "../common/net-reviews/net-reviews-product-reviews.component.vue";
import AsideCtaInterventionComponent from "../common/aside-cta/aside-cta-intervention.component.vue";
import AsideCtaYoutubeComponent from "../common/aside-cta/aside-cta-youtube.component.vue";
import ProductReviewModalComponent from "../common/product-review/product-review-modal.component.vue";

declare var partData: any;
declare var netReviewsProductWidget: any;

if (document.querySelector(".PartDetailPage")) {

  @Component({
    components: {
      "photo-modal": PhotoModalComponent,
      "compatibility-checker": CompatibilityChecker,
      "net-reviews-product-reviews": NetReviewsProductReviews,
      "cta-intervention": AsideCtaInterventionComponent,
      "cta-youtube": AsideCtaYoutubeComponent,
      "productreviews-modal": ProductReviewModalComponent,
    },
  })
  class PartDetailPage extends BrandtVue {

    public activeTab = "description";
    public showModal = false;
    public part: any = partData;  // Initialise l'objet accessory depuis Thymeleaf
    public netReviewsProductWidgetUrl: any = netReviewsProductWidget;


      emitProductReviewsList(event: any){
          this.$emit("on-productreview-call", {product: this.part});
      }

  }

  const partDetailPage = new PartDetailPage({ el: ".PartDetailPage" });

}
