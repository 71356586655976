import Component from "vue-class-component";
import BrandtVue from "../common/brandt-vue";

import "../common/aside-cta/aside-with-cta.scss";
import PhotoModalComponent from "../common/ui/photo-modal.component.vue";
import NetReviewsProductReviews from "../common/net-reviews/net-reviews-product-reviews.component.vue";
import AsideCtaInterventionComponent from "../common/aside-cta/aside-cta-intervention.component.vue";
import AsideCtaYoutubeComponent from "../common/aside-cta/aside-cta-youtube.component.vue";
import CompatibilityChecker from "../compatibility-check/compatibility-checker.vue";
import {id} from "postcss-selector-parser";
declare var accessoryData: any;
declare var netReviewsProductWidget: any;

if (document.querySelector(".AccessoryDetailPage")) {



    @Component({
        components: {
            "photo-modal": PhotoModalComponent,
            "compatibility-checker": CompatibilityChecker,
            "net-reviews-product-reviews": NetReviewsProductReviews,
            "cta-intervention": AsideCtaInterventionComponent,
            "cta-youtube": AsideCtaYoutubeComponent
         },
    })
    class AccessoryDetailPage extends BrandtVue {
        public showPhotoModal = false;
        public activeTab = "description";
        public accessory: any = accessoryData;  // Initialise l'objet accessory depuis Thymeleaf
        public netReviewsProductWidgetUrl:any = netReviewsProductWidget


        emitProductReviewsList(event: any){
            this.$emit("on-productreview-call", {product: this.accessory});
        }
    }

    const accessoryDetailPage = new AccessoryDetailPage({ el: ".AccessoryDetailPage" });

}
