




















































import Component, {mixins} from "vue-class-component";
import {RequestStatus} from "../../../common/models/request-status.enum";
import SpinnerComponent from "../../../common/ui/spinner.component.vue";
import {InterventionCreateRequestType} from "./intervention-create-request-type.enum";
import interventionStore from "./intervention.store";
import {TechnicalStationPublic} from "../../models/technical-station-public";
import interventionRdvService from "../../services/intervention-rdv.service";
import {maxLength, required, minLength, numeric} from "vuelidate/lib/validators";
import zipCodeValidator from "../../../common/validators/zip-code.validator";
import {validationMixin} from "vuelidate";
import radioBtnWarrantyService from "../../services/radio-btn-warranty.service";


@Component({
    components: {
        spinner: SpinnerComponent
    },
    validations: {
        zipCode: {
            zipCode: function(value: string) {
                return zipCodeValidator.validate(value, "FR");
            },
            required,
            numeric,
            minLength: minLength(5),
            maxLength: maxLength(5)
        }

    }
} as any)
export default class InterventionCheckTypeComponent extends mixins(validationMixin){
    public $v: any;
    RequestStatus = RequestStatus;
    checkStaStatus = RequestStatus.Initial;
    public zipCode= '';
    technicalStation: TechnicalStationPublic = null;
    public verificationPending = false;
    public $t: any;
    radioBtnChecked: boolean = null;

    checkInterventionRequestType() {
        if(this.radioBtnChecked === null || this.radioBtnChecked === false){
            this.radioBtnChecked = false;
            return;
        }

        if(this.$v.invalid && this.$v.dirty){
            return;
        }
        this.checkStaStatus = RequestStatus.Pending;

        (async () => {
            await this.loadTechnicalStation(this.zipCode);
            let sta = this.technicalStation !== null;
            let warranty = interventionStore.state.underWarranty;
            this.identifyType(warranty, sta);

            this.checkStaStatus = RequestStatus.Success;

            interventionStore.setZipCode(this.zipCode);
            interventionStore.setTechnicalStation(this.technicalStation);

            this.$emit('interventionCreateRequestTypeDetermined', interventionStore.state.interventionCreateRequestType);
        })();
    }

    public async loadTechnicalStation(zipCode: string) {
        try{
            this.technicalStation = await interventionRdvService.getTechnicalStationByZipCode(zipCode);
        }catch (err){

        }
    }

    public getErrorMessageKey(validation: any) {
        if (validation.maxLength === false) {
            return "common.error.field-max-length";
        } else if (validation.minLength === false){
            return this.$t("common.error.field-min-length", [5]);
        } else if (validation.numeric === false){
            return "common.error.field-invalid-zip-code-numeric";
        } else if (validation.zipCode === false) {
            return "common.error.field-invalid-zip-code";
        } else {
            return "common.error.field-required";
        }
    }

    private identifyType(warranty: boolean, sta: boolean){
        if(warranty){
            if(sta){
                interventionStore.setInterventionCreateRequestType(InterventionCreateRequestType.StaWarranty)
            }else {
                interventionStore.setInterventionCreateRequestType(InterventionCreateRequestType.Warranty)
            }
        }else{
            if(sta){
                interventionStore.setInterventionCreateRequestType(InterventionCreateRequestType.StaNoWarranty)
            }else {
                interventionStore.setInterventionCreateRequestType(InterventionCreateRequestType.NoWarranty)
            }
        }
    }


    mounted() {
        radioBtnWarrantyService.$on('warrantychange', this.radioBtnIsChecked);
    }
    beforeDestroy() {
        radioBtnWarrantyService.$off('warrantychange', this.radioBtnIsChecked);
    }

    radioBtnIsChecked(){
        this.radioBtnChecked = true;
    }
}
