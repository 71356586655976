import { render, staticRenderFns } from "./order-relay-point-address.vue?vue&type=template&id=5eb545b1&scoped=true&"
import script from "./order-relay-point-address.vue?vue&type=script&lang=ts&"
export * from "./order-relay-point-address.vue?vue&type=script&lang=ts&"
import style0 from "./order-relay-point-address.vue?vue&type=style&index=0&id=5eb545b1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb545b1",
  null
  
)

component.options.__file = "order-relay-point-address.vue"
export default component.exports