
































































import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route} from "vue-router";
import AssistanceChoiceBoxComponent from "./assistance-choice-box.component.vue";
import PrerequisitModalComponent from "./assistance-choice-prerequisit-modal.component.vue"
import assistanceStore from "./assistance.store";
import {AssistanceType} from "../assistance-type.enum";

export interface AssistanceChoiceBoxData {
    title: string;
    image: string;
    infoLabel?: string;
    duration: string;
    steps: string[];
    price: string;
    type: AssistanceType;
    moreInfos: string;
}

declare var diagnosticPrice: string;
declare var repairPrice: string;

@Component({
    components: {
        modal: PrerequisitModalComponent,
        "choice-box": AssistanceChoiceBoxComponent
    }
})
export default class AssistanceChoiceComponent extends Vue {

    public $router: VueRouter;
    public $route: Route;
    public displayModal: boolean = false;
    public reference: string;

    // objects that will contains the necessary infos (img, title, text, etc)
    // for the two cards
    private readonly diagnostic: AssistanceChoiceBoxData = {
        title: 'assistance.choice.diagnostic.title',
        image: 'diagnostic.jpeg',
        duration: 'assistance.choice.diagnostic.duration',
        steps: [
            'assistance.choice.diagnostic.steps.1',
            'assistance.choice.diagnostic.steps.2',
            'assistance.choice.diagnostic.steps.3'
        ],
        price: diagnosticPrice,
        type: AssistanceType.DIAGNOSTIC,
        moreInfos: 'assistance.choice.box.infosDiag'
    };
    private readonly technical_assistance: AssistanceChoiceBoxData= {
        title: 'assistance.choice.technical_assistance.title',
        image: 'technical_assistance.jpg',
        infoLabel: 'assistance.choice.technical_assistance.infoLabel',
        duration: 'assistance.choice.technical_assistance.duration',
        steps: [
            'assistance.choice.technical_assistance.steps.1',
            'assistance.choice.technical_assistance.steps.2',
            'assistance.choice.technical_assistance.steps.3'
        ],
        price: repairPrice,
        type: AssistanceType.TECHNICAL,
        moreInfos: 'assistance.choice.box.infosTech'
    };

    created(){
        this.reference = assistanceStore.endProduct.reference;
    }

    showModal(){
        this.displayModal = true;
    }

}
