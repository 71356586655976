import * as _ from "lodash";
import authenticationService from "../common/authentication/authentication.service";
import apiClient from "../common/services/api.client";
import localStorageService from "../common/services/local-storage.service";
import { CartAssistanceRdv } from "./assistance-rdv.model";
import { Cart } from "./cart";
import { CartItem } from "./cart-item";
import {ShippingCode} from "../common/ui/shipping-code";

declare var locale: string;

export class CartService {

    public async getCart(shouldRetry = true): Promise<Cart> {
        const { url, creationOnly } = this.getCartInfo();
        let cart: Cart;
        if (creationOnly) {
            cart = { items: [], countryCode: null, promoCode: null, itemsAmount: 0, discountAmount: 0, shippingCode: ShippingCode.STANDARD };
        } else {
            try {
                cart = await apiClient.get<Cart>(url, { locale });

                // Evite un bug dans le cas ou en base le shippingCode est différent des méthodes de livraison disponibles
                // cela peut etre du a un changement de metadata produit par exemple
                // le panier est figé et on est bloqué et renvoyé sur la selection de relay point donc ça bug
                if (cart.shippingCode !== ShippingCode.STANDARD) {
                    if (!cart.availableShippingMethods?.some(method => method.shippingCode === cart.shippingCode)) {
                        cart.shippingCode = ShippingCode.STANDARD;
                        cart = await this.updateCart(cart);
                    }
                }

            } catch (error) {
                if (shouldRetry) {
                    this.removeCartId();
                    cart = await this.getCart(false);
                }
            }
        }

        if (!cart) {
            cart = { items: [], countryCode: null, promoCode: null, itemsAmount: 0, discountAmount: 0, shippingCode: ShippingCode.STANDARD };
        }
        return cart;
    }

    public async getCount(shouldRetry = true): Promise<number> {
        const { url, creationOnly } = this.getCartInfo();
        if (creationOnly) {
            return 0;
        } else {
            try {
                return await apiClient.get<number>(url + "/count");
            } catch (error) {
                if (shouldRetry) {
                    this.removeCartId();
                    return this.getCount(false);
                }
                return 0;
            }
        }
    }

    public async removeItem(
        productId: string
    ): Promise<Cart> {
        const { cartId, url } = this.getCartInfo();
        const cart = await apiClient.delete<Cart>(url, { cartId, productId, locale });
        this.setCartId(cart.id);
        return cart;
    }

    public async updateCart(cart: Cart): Promise<Cart> {
        const { url } = this.getCartInfo();

        try{
            let updatedCart = await apiClient.patch<Cart, any>(url, {
                id: cart.id,
                countryCode: cart.countryCode,
                promoCode: cart.promoCode,
                shippingCode: cart.shippingCode,
            }, { locale });

            // Evite un bug dans le cas ou en base le shippingCode est différent des méthodes de livraison disponibles
            // cela peut etre du a un changement de metadata produit par exemple
            // le panier est figé et on est bloqué et renvoyé sur la selection de relay point donc ça bug
            if (updatedCart.shippingCode !== ShippingCode.STANDARD) {
                if (!updatedCart.availableShippingMethods?.some(method => method.shippingCode === updatedCart.shippingCode)) {
                    updatedCart.shippingCode = ShippingCode.STANDARD;
                    updatedCart = await this.updateCart(cart);
                }
            }
            this.setCartId(updatedCart.id);
            return updatedCart;
        } catch (error){
            return this.getCart();
        }
    }

    /**
     * Upserts a product in the cart.
     * @param product - The product to upsert.
     * @param quantity - The quantity to upsert.
     * @param index - The index to insert the product at (if not out of bounds).
     * If it is out of bounds, the item will be inserted at the end of the items list.
     */
    public async upsertProduct(
        productId: string,
        quantity: number,
        index: number,
        assistanceRdv: CartAssistanceRdv | null
    ): Promise<Cart> {
        const { url, cartId } = this.getCartInfo();
        try {
            const cart = await apiClient.put<Cart, any>(url, { cartId, productId, quantity, displayIndex: index, assistanceRdv, locale });
            this.setCartId(cart.id);
            return cart;
        } catch (err) {
            if( _.get( err, 'response.data.code') === 'CART_NOT_FOUND'){
                this.removeCartId();
                return this.upsertProduct(productId, quantity, index, assistanceRdv);
            } else {
                throw err;
            }
        }
    }

    public async insertAssistanceProduct(assistanceProduct: any): Promise<Cart> {
        const cartId = this.getCartId();

        try {
            var url  = '/carts';
            if (authenticationService.isAuthenticated()) {
                url += '/me';
            }
            url  += '/add-assistance';

            const cart = await apiClient.put<Cart, any>(url, { cartId, ...assistanceProduct, locale });
            this.setCartId(cart.id);
            return cart;
        } catch (err) {
            if( _.get( err, 'response.data.code') === 'CART_NOT_FOUND'){
                this.removeCartId();
                return this.insertAssistanceProduct(assistanceProduct);
            } else {
                throw err;
            }
        }
    }

    public getCartId() {
        return localStorageService.getItem("cartId");
    }

    public removeCartId() {
        return localStorageService.removeItem("cartId");
    }

    private setCartId(cartId: string) {
        if (!authenticationService.isAuthenticated()) {
            localStorageService.setItem("cartId", cartId);
        }
    }

    private getCartInfo(): { url: string, cartId: string, creationOnly: boolean } {
        const cartId = this.getCartId();

        let creationOnly: boolean;
        let url = "/carts";

        if (authenticationService.isAuthenticated()) {
            url += '/me';
            creationOnly = false;
        } else if (!!cartId) {
            url += `/${cartId}`;
            creationOnly = false;
        } else {
            creationOnly = true;
        }

        return { url, cartId, creationOnly };
    }

    public async updateCartAssistanceRdv(assistanceRdvId: CartItem, cartId: String){
        let url ="/carts";
        if (authenticationService.isAuthenticated()) {
            url += "/me";
        }
        url += "/update-assistance-rdv";
        const cart = await apiClient.put<Cart, any>(url, {cartItem: assistanceRdvId, cartId: cartId, locale: locale});
        this.setCartId(cart.id);
        return cart;
    }

    public async checkAssistanceRdv(shouldRetry = true): Promise<void> {
        let url ="/carts";
        if (authenticationService.isAuthenticated()) {
            url += "/me";
        }
        url += "/check-assistance";
        try {
            await apiClient.put(url, { id: this.getCartId(), locale: locale });
        } catch (error) {
            if (shouldRetry) {
                await this.checkAssistanceRdv (false);
            }
        }

    }
}

export default new CartService();
