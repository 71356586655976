







































































import Component, {mixins} from "vue-class-component";
import AlertComponent from "../../../common/ui/alert.component.vue";
import supportRequestsService from "../../../common/support/support-requests.service";
import {required, minLength, numeric} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import interventionStore from "./intervention.store";
import authenticationService from "../../../common/authentication/authentication.service";
import loginModalService from "../../../common/authentication/login-modal.service";
import phoneNumberValidator from "../../../common/validators/phone-number.validator";

declare var privacyPolicyLink: string;


@Component({
    components: {
        alert: AlertComponent,
    },
    validations: {
        phoneNumber: {
            checkPhoneNumber: function (phoneNumber: string){
                return phoneNumberValidator.validate(phoneNumber);
            },
            required
        }
    }
} as any)
export default class InterventionStaWarrantyFormComponent extends mixins(validationMixin) {
    public privacyPolicyLink = privacyPolicyLink;
    public $v: any;
    phoneNumber: string = '';
    isSuccess: boolean = false;
    isError: boolean = false;
    public $t: any;
    lockBtn: boolean = false;

    async beCalledBackSubmitted() {
        try {
            if (!authenticationService.isAuthenticated()){
                loginModalService.emitLoginModal();
                return;
            }
            this.lockBtn = true;
            const reference = window.location.href.split("reference=")[1] != null ?
                window.location.href.split("reference=")[1] : null;
            let zipCode = interventionStore.state.zipCode;
            //await supportRequestsService.staWarrantySupportRequest("interventionForm", this.phoneNumber, reference, zipCode);
            await supportRequestsService.requestACallBack("interventionForm", this.phoneNumber);
            this.isSuccess = true;
            this.isError = false;
        } catch (err) {
            this.lockBtn = false;

            this.isSuccess = false;
            this.isError = true;
        }
    }

    public getErrorMessageKey(validation: any) {
        if (validation.checkPhoneNumber === false){
            return "assistance.formulaire.phoneNumber.error";
        }
        if (validation.minLength === false){
            return this.$t("common.error.field-min-length", [10]);
        }  else if (validation.numeric === false){
            return "common.error.field-invalid-phone-numeric";
        } else if (validation.required == false){
            return "common.error.field-required";
        }
    }
}
