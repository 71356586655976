export interface ColissimoResult {
    accesPersonneMobiliteReduite: boolean;
    adresse1: string;
    adresse2?: string;
    adresse3?: string;
    codePostal: string;
    congesPartiel?: boolean;
    congesTotal?: boolean;
    coordGeolocalisationLatitude?: string;
    coordGeolocalisationLongitude?: string;
    distanceEnMetre?: number;
    horairesOuvertureDimanche?: string;
    horairesOuvertureLundi?: string;
    horairesOuvertureMardi?: string;
    horairesOuvertureMercredi?: string;
    horairesOuvertureJeudi?: string;
    horairesOuvertureVendredi?: string;
    horairesOuvertureSamedi?: string;
    identifiant: string;
    indiceDeLocalisation?: string;
    listeConges?: {
        calendarDeDebut?: number;
        calendarDeFin?: number;
        numero?: number;
    }[];
    localite: string;
    nom: string;
    periodeActiviteHoraireDeb?: string;
    periodeActiviteHoraireFin?: string;
    poidsMaxi?: number;
    typeDePoint?: string;
    codePays: string;
    langue?: string;
    libellePays?: string;
    loanOfHandlingTool?: boolean;
    parking?: boolean;
    reseau?: string;
    distributionSort?: string;
    lotAcheminement?: string;
    versionPlanTri?: string;
}

declare var urlColissimoWidget: string;
declare var colissimoToken: string;


export class ColissimoService {

    public async getColissimoWidgetUrl(): Promise<string> {
        return Promise.resolve(urlColissimoWidget);
    }

    public async getColissimoToken(): Promise<string> {
        return Promise.resolve(colissimoToken);
    }
}
export default new ColissimoService();
