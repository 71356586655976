















































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import FieldComponent from "../../../common/ui/field.component.vue";
import assistanceStore from "./assistance.store";
import {ROUTES} from "../../assistance.page";
import VueRouter, {Route} from "vue-router";
import {AssistanceType} from "../assistance-type.enum";

declare var homeLink: string;

@Component({
    components: {
        field: FieldComponent
    },
    props: {
        supportForRepairs: Boolean
    },
    watch: {}
})
export default class PrerequisitModalComponent extends Vue {
    public $router: VueRouter;
    public $route: Route;
    private isReady: boolean = false;
    private hasDiagnostic: boolean = false;
    private hasSpareParts: boolean = false;

    public homeLink = homeLink;
    public state = assistanceStore.state;

    created() {
        this.isReady = false;
    }

    allowNextStep() {
        this.isReady = this.hasDiagnostic && this.hasSpareParts;
    }

    updateSpareParts(value: boolean) {
        this.hasSpareParts = value;
        assistanceStore.setSpareParts(this.hasSpareParts);
        this.allowNextStep();
    }

    updateDiagnostic(value: boolean) {
        this.hasDiagnostic = value;
        assistanceStore.setDiagnostic(this.hasDiagnostic);
        this.allowNextStep();
    }

    goToNextStep() {
        assistanceStore.setAssistanceType(AssistanceType.TECHNICAL);
        // route to rdv choice
        this.$router.push({path: ROUTES.TIMESLOT, query: Object.assign({}, this.$route.query, {assistanceType: assistanceStore.assistanceType}) });
    }

    public stopPropagation($event: Event) {
        $event.stopPropagation();
    }
}
