import Vue from "vue";
import Component from "vue-class-component";
import ProductReviewModalComponent from "./product-review/product-review-modal.component.vue";
@Component({
    components: {
        "productreviews-modal": ProductReviewModalComponent,
    },
})
export default class ProductReviewsMixin extends Vue {

    public product: any = null;
    public showProdutReviewModal = false;

    public created() {
        this.$on("on-productreview-call", (event: any) => {
            this.product = event.product;
            this.showProdutReviewModal = true;
        });
    }



    public toggleDisplayProductReviewModal(){
        this.showProdutReviewModal = !this.showProdutReviewModal;
    }

}
