































































































import Vue from "vue";
import Component from "vue-class-component";
import ModalComponent from "../common/ui/modal.component.vue";

@Component({
  name: "delivery-error-modal",
  props: {
      title: {
          type: String,
          required: false
      },
      message: {
          type: String,
          required: false
      }
  },
  components: {
    modal: ModalComponent
  }
})
export default class DeliveryErrorModalComponent extends Vue {

  constructor() {
    super();

  }
}
