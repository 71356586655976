






















import Vue from "vue";
import Component from "vue-class-component";
import TimeslotSelectionComponent from "../assistance/step/shared/timeslot-selection.component.vue";
import {TimeslotEvent} from "../common/models/timeslot-event.model";

@Component({
    components:{
        'timeslot-selection': TimeslotSelectionComponent
    },
    props: {
        type: String,
        error: Boolean,
        assistanceId: String
    }
})
export default class TimeslotModalComponent extends Vue {

    public confirm(event: TimeslotEvent) {
        this.$emit('confirm', event);
    }

    public cancel() {
        this.$emit('close');
    }

    public stopPropagation($event: Event) {
        $event.stopPropagation();
    }

}
