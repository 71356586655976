
import {Vue, Component} from 'vue-property-decorator';
import Address from "../../account/address";

@Component({
    props: {
        rpAddress: Object
    }
})
export default class OrderRelayPointAddressComponent extends Vue
{
    public rpAddress: Address;
}
