import VueRouter from "vue-router";
import apiClient from "../../common/services/api.client";
import {BrandPlan} from "../models/brand-plan";
import {FindMaintenanceContract} from "../models/find-maintenance-contract";
import {FindInterventionTimeslots} from "../models/find-intervention-timeslots";
import { MaintenanceContractCustomer } from "../models/maintenance-contract-customer";
import {ReserveInterventionTimeslot} from "../models/reserve-intervention-timeslot";
import { TechnicalStationPublic } from "../models/technical-station-public";
import { WarrantyPublic } from "../models/warranty-public";
import {Contract} from "../models/contract";
import {InterventionTimeslotsResults} from "../models/intervention-timeslots-results";

declare var locale: string;

export class InterventionRDVService {
    public $router: VueRouter;

    public async getTechnicalStationByZipCode(zipCode: string) {
        return apiClient.get<TechnicalStationPublic>(`/intervention/technical-station?zipCode=${zipCode}`);
    }

    public async getWarrantiesByType(type: string) {
        return apiClient.get<WarrantyPublic[]>(`/intervention/warranties?type=${type}`);
    }

    public async getPlanByReference(reference: string) {
        return apiClient.get<BrandPlan[]>(`/intervention/plan?reference=${reference}`);
    }

    public async contractIsGuaranteed(dto: FindMaintenanceContract): Promise<MaintenanceContractCustomer> {
        return apiClient.post<MaintenanceContractCustomer, FindMaintenanceContract>("/intervention/maintenance-contract", dto);
    }

    public async findContractByCommercialRefAndNameHolder(dto: FindContract): Promise<Contract> {
        return apiClient.post<Contract, FindContract>("/intervention/find-contract", dto);

    }

    public async getInterventionTimeSlots(timeSlotRequest: FindInterventionTimeslots): Promise<InterventionTimeslotsResults> {
        return apiClient.post<InterventionTimeslotsResults, FindInterventionTimeslots>("/intervention/get-time-slots", timeSlotRequest);
    }

    public async reserveInterventionTimeSlots(reserveTimeSlotRequest: ReserveInterventionTimeslot): Promise<void> {
        return apiClient.put<void, ReserveInterventionTimeslot>("/intervention/reserve-timeslot",
            reserveTimeSlotRequest);
    }



}

export default new InterventionRDVService();
