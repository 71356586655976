




























import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route} from "vue-router";
import {ROUTES} from "../../assistance.page";
import assistanceStore from "./assistance.store";
import TimeslotSelectionComponent from "../shared/timeslot-selection.component.vue";
import {AssistanceSearchType} from "./assistance-search.type.enum";
import {AssistanceType} from "../assistance-type.enum";
import {TimeslotEvent} from "../../../common/models/timeslot-event.model";

@Component({
    components:{
        'timeslot-selection': TimeslotSelectionComponent
    },
    props: {
        product: Object
    }
})
export default class AssistanceTimeslotSelectionComponent extends Vue {
    public $router: VueRouter;
    public $route: Route;
    public reference: string;
    public displayTechTitle: boolean = true;
    public assistanceType: string;

    created(){
        this.assistanceType = assistanceStore.assistanceType == AssistanceType.DIAGNOSTIC ? AssistanceSearchType.DIAGNOSTIC : AssistanceSearchType.ASSISTANCE;
        this.displayTechTitle = assistanceStore.assistanceType === AssistanceType.TECHNICAL;
        this.reference = assistanceStore.endProduct.reference;
    }

    public cancel() {
        this.$router.push({ path: ROUTES.CHOICE, query: this.$route.query });
    }

    public confirm(event: TimeslotEvent) {
        assistanceStore.setSelectedTimeslot(event.selectedTimeSlot);
        this.$router.push({ path: ROUTES.ADD_TO_CART, query: this.$route.query });
    }
}
