























import Component from "vue-class-component";
import accountService from "../account.service";
import PagerComponent from "../../common/ui/pager.component.vue";
import {Route} from "vue-router";
import AlertComponent from "../../common/ui/alert.component.vue";
import {AccountAssistanceRdv} from "./assistance-rdv.model";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import CrossSellingComponent from "../../common/cross-selling/cross-selling.component.vue";
import {Timeslot} from "../../common/models/timeslot.model";
import UtilsService from "../../common/services/utils.service";
import i18nService from "../../common/i18n.service";
import CartMixin from "../../cart/cart.mixin";
import assistanceRdvService from "../../assistance/services/assistance-rdv.service";

const i18n = i18nService.getI18n();

@Component({
    components: {
        pager: PagerComponent,
        alert: AlertComponent,
        'fa-icon': FontAwesomeIcon,
        'cross-selling': CrossSellingComponent
    },
    props: {
        context: Object,
        account: Object
    }
})
export default class AssistanceCrossSellingComponent extends CartMixin {

    public $route: Route;
    public context: any;
    public assistanceId: string = "";
    public title = "";
    public type = "";
    public assistance: AccountAssistanceRdv;

    public created() {
        this.assistanceId = this.$route.params.id;
        (async () => {
            await this.loadAssistanceInfos(this.assistanceId);
            this.title = this.formatDate(this.assistance);

            // TODO create mixin to reuse AssistanceListComponent.getTypeLabelKey
            this.type = i18n.t(assistanceRdvService.getTypeLabelKey(this.assistance)).toString();
        })();
    }

    // Do not use the local cartMixin but use the parent cartMixin, which is connected to the header.
    // It will update the number of items in the cart.
    public async addProduct(product: any) {
        const parent = this.$parent.$parent as any;
        parent.addProductToCart(product);
    }

    public async loadAssistanceInfos(assistanceId: String) {
        this.context.isLoading = true;
        try {
            this.assistance = await accountService.getAssistance(this.assistanceId);
        } catch (err) {

        }
        this.context.isLoading = false;
    }

    public formatDate(assistance: AccountAssistanceRdv): string {
        const tSlot: Timeslot = {
            id: assistance.assistanceRdv.id,
            startTime: assistance.assistanceRdv.timeSlotStartTime,
            endTime: assistance.assistanceRdv.timeSlotEndTime
        }
        return UtilsService.formatTimeslotDateTime(tSlot);
    }
}
