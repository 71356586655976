














import Component from "vue-class-component";
import Vue from "vue";
import InterventionFormDataComponent from "./intervention-form-data.component.vue";
import InterventionFormAddressComponent from "./intervention-form-address.component.vue";
import InterventionTimeslotSelectionComponent from "./intervention-timeslot-selection.component.vue";

enum Step {
    Data,
    Address,
    Timeslot
}

@Component({
    components: {
        'intervention-form-data': InterventionFormDataComponent,
        'intervention-form-address': InterventionFormAddressComponent,
        'intervention-timeslot': InterventionTimeslotSelectionComponent
    }
}as any)
export default class InterventionFormComponent extends Vue {
    Step = Step;
    step = Step.Data;

    back() {
        this.$emit('back');
    }
}
