
























import Component from "vue-class-component";
import Vue from "vue";
import {RequestStatus} from "../../../common/models/request-status.enum";
import interventionStore from "./intervention.store";

@Component({

})
export default class InterventionStaNoWarrantyFormComponent extends Vue {
    stationPhoneNumber = "";
    retrieveStaPhoneNumberRequest = RequestStatus.Initial;

    mounted() {
        this.retrieveStaPhoneNumberRequest = RequestStatus.Pending;
        let technicalStation = interventionStore.state.technicalStation;
        return this.stationPhoneNumber = this.sanitizePhoneNumber(technicalStation.contactPhone);
    }

    sanitizePhoneNumber(phoneNumber: string): string {
        return phoneNumber.replace(/[^0-9]/g, '');
    }
}
