import moment from "moment-timezone";
import {Timeslot} from "../models/timeslot.model";
import momentDateFormatService from "../momentDateFormat.service";

export class UtilsService {

    public formatTimeslotDateTime(timeslot: Timeslot): string {

        moment.locale("fr");
        var start = moment(String(timeslot.startTime));
        var end = moment(String(timeslot.endTime));
        var date =
            moment(start).local().format("dddd DD MMMM Y") +
            " de " +
            moment(start).local().format("HH:mm") +
            " à " +
            moment(end).local().format("HH:mm");
        return this.capitalize(date);
    }

    public formatTimeslotZoneDateTime(timeslot: Timeslot): Timeslot {
        timeslot.startTime = momentDateFormatService.ISO8601StringToMomentToString(timeslot.startTime);
        timeslot.endTime = momentDateFormatService.ISO8601StringToMomentToString(timeslot.endTime);
        return timeslot;
    }

    public formatTimeslotDateOnly(timeslot: Timeslot): string {
        moment.locale("fr");
        let date = "ERROR TO DISPLAY DATE";
        if (!!timeslot.startTime) {
            const start = moment(String(timeslot.startTime));
            date = moment(start).local().format("dddd DD MMMM Y");
        }
        return this.capitalize(date);
    }

    public capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}

export default new UtilsService();
