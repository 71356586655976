






























































































































import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";

import AlertComponent from "../../common/ui/alert.component.vue";
import VueRouter, { Route } from "vue-router";
import { ROUTES } from "./order.page";
import OrderStore  from "./order.store";

@Component({
    components: {
        alert: AlertComponent,
    },
    props: {
        countries: Array,
        context: Object,
        errorCodeKey: String,
        cart: Object,
        order: Object,
        isLoaded: Boolean,
        paymentMethods: Array
    },
})
export default class QuoteTunnelComponent extends Vue {

    public $route: Route;
    public $router: VueRouter;

    public steps = [
        { name: "PREFERENCES", labelKey: "order.quote-tunnel-delivery-step-label", route: ROUTES.PREFERENCES },
        { name: "PAYMENT", labelKey: "order.quote-tunnel-payment-step-label", route: ROUTES.PAYMENT }
    ];

    public isActive(route: string) {
        return route === this.$route.path;
    }

    public getCurrentIndex() {
        return _.findIndex(this.steps, step => step.route === this.$route.path);
    }

    public back() {
        OrderStore.setStepRelayPointToSummary();
        this.$router.push({ path: '/' });
    }
}
