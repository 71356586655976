import axios from 'axios';
import { GeocodeResponseDTO } from './geocodeResponse.dto';
import { ColissimoPayloadWidget } from './colissimo-payload-widget.model';

export class GeocodingService {
    private apiUrl: string = 'https://api-adresse.data.gouv.fr/reverse/';


    public async getGeocode(lon: number, lat: number): Promise<GeocodeResponseDTO> {
        try {
            const response = await axios.get(this.apiUrl, {
                params: {
                    lon: lon,
                    lat: lat,
                    limit: 1
                }
            });
            return response.data;
        } catch (error) {

            throw error;
        }
    }

    public async getColissimoAddress(lon: number, lat: number): Promise<ColissimoPayloadWidget> {
        try {
            const response = await this.getGeocode(lon, lat);
            const feature = response.features[0];
            return {
                city: feature.properties.city,
                postcode: feature.properties.postcode,
                street: feature.properties.street
            };
        } catch (error) {

            throw error;
        }
    }
}

export default new GeocodingService();
