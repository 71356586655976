import Vue from 'vue';

class RadioBtnWarranty extends Vue {
    emitWarrantyChange(): void {
        this.$emit('warrantychange');
    }

    hideRadioWarranty(): void {
        this.$emit('hideradiowarranty');
    }

    reviveRadioWarranty(): void {
        this.$emit('reviveradiowarranty');
    }
}
export default new RadioBtnWarranty();
