import {Timeslot} from "../../../common/models/timeslot.model";
import {AddressIntervention} from "../../models/address-intervention";
import {MaintenanceContractCustomer} from "../../models/maintenance-contract-customer";
import {TechnicalStationPublic} from "../../models/technical-station-public";
import {InterventionCreateRequestType} from "./intervention-create-request-type.enum";

export interface InterventionState {
    interventionCreateRequestType: InterventionCreateRequestType;
    technicalStation: TechnicalStationPublic;
    underWarranty: boolean;
    zipCode: string;
    city: string;
    referenceUnit: string;
    purchaseDate: Date;
    nameContractHolder: string;
    serialNumber: string;
    commercialReference: string;
    technicalReference: string;
    contractNumber: string;
    purchaseStore: string;
    description: string;
    phoneNumber: string;
    email: string;
    customerDetails: MaintenanceContractCustomer;
    addressIntervention: AddressIntervention;
    selectedTimeslot: Timeslot;
    brandPlanId: string;
    suggestedTimeSlots: Timeslot[];
}

export class InterventionStore {
    private _initialState: InterventionState = {
        interventionCreateRequestType: null,
        technicalStation: null,
        underWarranty: null,
        zipCode: undefined,
        city: undefined,
        referenceUnit: undefined,
        purchaseDate: undefined,
        nameContractHolder: undefined,
        serialNumber: undefined,
        commercialReference: undefined,
        technicalReference: undefined,
        contractNumber: undefined,
        purchaseStore: undefined,
        description: undefined,
        phoneNumber: undefined,
        email: undefined,
        customerDetails: undefined,
        addressIntervention: undefined,
        selectedTimeslot: undefined,
        brandPlanId: undefined,
        suggestedTimeSlots: null
    };

    private _state: InterventionState = { ...this._initialState };

    public get state() {
        return this._state;
    }

    public get interventionCreateRequestType() {
        return this._state.interventionCreateRequestType;
    }

    public get technicalStation() {
        return this._state.technicalStation;
    }

    public get underWarranty() {
        return this._state.underWarranty;
    }

    public get zipCode() {
        return this._state.zipCode;
    }

    public get city() {
        return this._state.city;
    }

    public get referenceUnit() {
        return this._state.referenceUnit;
    }

    public get purchaseDate() {
        return this._state.purchaseDate;
    }

    public get nameContractHolder() {
        return this._state.nameContractHolder;
    }

    public get serialNumber() {
        return this._state.serialNumber;
    }
    public get commercialReference() {
        return this._state.commercialReference;
    }
    public get technicalReference() {
        return this._state.technicalReference;
    }
    public get contractNumber() {
        return this._state.contractNumber;
    }

    public get purchaseStore() {
        return this._state.purchaseStore;
    }

    public get description() {
        return this._state.description;
    }

    public get phoneNumber() {
        return this._state.phoneNumber;
    }

    public get email() {
        return this._state.email;
    }

    public get customerDetails() {
        return this._state.customerDetails;
    }

    public get addressIntervention() {
        return this._state.addressIntervention;
    }
    public get selectedTimeslot() {
        return this._state.selectedTimeslot;
    }

    public get brandPlanId() {
        return this._state.brandPlanId;
    }

    public get suggestedTimeSlots() {
        return this._state.suggestedTimeSlots;
    }

    public setInterventionCreateRequestType(interventionCreateRequestType: InterventionCreateRequestType) {
         this._state.interventionCreateRequestType = interventionCreateRequestType;
    }
    public setTechnicalStation(technicalStation: TechnicalStationPublic) {
        this._state.technicalStation = technicalStation;
    }

    public setUnderWarranty(underWarranty: boolean) {
        this._state.underWarranty = underWarranty;
    }

    public setZipCode(zipcode: string) {
        this._state.zipCode =  zipcode;
    }

    public setCity(city: string) {
        this._state.city = city;
    }

    public setReferenceUnit(referenceUnit: string) {
        this._state.referenceUnit = referenceUnit;
    }

    public setPurchaseDate(purchaseDate: Date) {
        this._state.purchaseDate = purchaseDate;
    }

    public setNameContractHolder(nameContractHolder: string) {
        this._state.nameContractHolder = nameContractHolder;
    }

    public setSerialNumber(serialNumber: string) {
        this._state.serialNumber = serialNumber;
    }
    public setCommercialReference(commercialRef: string) {
        this._state.commercialReference = commercialRef;
    }
    public setTechnicalReference(technicalRef: string) {
        this._state.technicalReference = technicalRef;
    }
    public setContractNumber(contractNumber: string) {
        this._state.contractNumber = contractNumber;
    }

    public setPurchaseStore(purchaseStore: string) {
        this._state.purchaseStore = purchaseStore;
    }

    public setDescription(description: string) {
        this._state.description = description;
    }

    public setPhoneNumber(phoneNumber: string) {
        this._state.phoneNumber = phoneNumber;
    }

    public setEmail(email: string) {
        this._state.email = email;
    }
    public setCustomerDetails(details: MaintenanceContractCustomer) {
        this._state.customerDetails = details;
    }

    public setAddressIntervention(address: AddressIntervention) {
        this._state.addressIntervention = address;
    }

    public setSelectedTimeslot(timeslot: Timeslot) {
        this._state.selectedTimeslot = timeslot;
    }

    public setBrandPlanId(brandPlanId: string) {
        this._state.brandPlanId = brandPlanId;
    }

    public setSuggestedTimeSlots(timeslots: Timeslot[]) {
        this._state.suggestedTimeSlots = timeslots;
    }

    public resetState() {
        this._state = { ...this._initialState };
    }
}

export default new InterventionStore();
