import Vue from "vue";
import Component, {mixins} from "vue-class-component";
import VueI18n from "vue-i18n";
import VueRouter from "vue-router";
import CartMixin from "../cart/cart.mixin";
import ProductAlertMixin from "../product/product-alert.mixin";
import BackToTopMixin from "./back-to-top.mixin";
import CookieBannerMixin from "./cookie/cookie-banner.mixin";
import i18nService from "./i18n.service";
import NavbarMixin from "./navbar.mixin";
import ProductReviewsMixin from "./product-reviews.mixin";
import AlertComponent from "./ui/alert.component.vue";
import NotificationMixin from "./ui/notification.mixin";
import fontAwesomeService from "./fontAwesome.service";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import NewsletterComponent from "../common/newsletter/newsletter.component.vue";
import VTooltip from "v-tooltip";
import LoadScript from "vue-plugin-load-script";

declare var dataLayer: any;
Vue.use(VueI18n);
Vue.use(VTooltip);
Vue.use(LoadScript as any);
const i18n = i18nService.getI18n();
// load the fa icon
fontAwesomeService.init();
@Component({
    components: {
        alert: AlertComponent,
        'fa-icon': FontAwesomeIcon,
        newsletter: NewsletterComponent
    },
    i18n,
})
export default class BrandtVue extends mixins(CartMixin, BackToTopMixin, NavbarMixin,
    NotificationMixin, CookieBannerMixin, ProductAlertMixin, ProductReviewsMixin) {

    public $router: VueRouter;
    public go(obj: any) {
        if (obj.dataLayer) {
            dataLayer.push({
                event: "select_item",
                ecommerce: {
                    items: [{...obj.dataLayer, item_list_name: obj.list || "Search Results"}],
                }
            });
        }
        window.location.href = obj.link;
    }

}
