export class UtilsHttpService {

    public loadCSS(href: string) {
        const link = document.createElement('link');
        link.href = href;
        link.rel = 'stylesheet';
        document.head.appendChild(link);
    }

    public loadScript(src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.type = 'text/javascript';
            script.async = true;
            script.onload = () => resolve();
            script.onerror = () => reject(new Error(`Cannot load script : ${src}`));
            document.head.appendChild(script);
        });
    }

}
export default new UtilsHttpService();
