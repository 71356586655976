import moment from "moment-timezone";
import "./polyfills";

import "./component-register-hooks";

import "../static-src/pictures/no-image.png";

import "../static-src/js/modernizr-custom";

import "./accessory/accessory-category-list.page";
import "./accessory/accessory-detail.page";
import "./accessory/accessory-list.page";
import "./account/account.page";
import "./assistance/assistance.page";
import "./article/article-detail.page";
import "./article/article.page";
import "./authenticate/login.page";
import "./cart/cart.page";
import "./end-product/end-product-category-list.page";
import "./end-product/end-product-detail.page";
import "./end-product/end-product-landing.page";
import "./error/error.page";
import "./home/home.page";
import "./order/step/order.page";
import "./page/contact.page";
import "./page/faq.page";
import "./page/qualirepar.page";
import "./page/find-reference.page";
import "./page/website-page.page";
import "./part/part-detail.page";
import "./products/product.page";
import "./reset-password/reset-password.page";
import "./page/marketing.page";
import "./landing-page/brand-landing-page-layout.page";

import "./payment/payment.page";
import "./payment/payment-cancel.page";

moment.tz.setDefault("Europe/Paris");

