import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import CartMixin from "../cart/cart.mixin";
import authenticationService from "./authentication/authentication.service";
import LoginModalComponent from "./authentication/login-modal.component.vue";

@Component({
  components: {
    "authentication-login-modal": LoginModalComponent,
  },
})
export default class NavbarMixin extends mixins(CartMixin) {

  public menuOpen = false;
  public showLoginModal = false;
  public isAuthenticated = false;
  public displayLang = false;

  public created() {
    this.refreshUserData();
  }
    mounted() {
        authenticationService.$on('isAuth', this.refreshUserData);
    }

    beforeDestroy() {
        authenticationService.$off('isAuth', this.refreshUserData);
    }

  public refreshUserData() {
    this.isAuthenticated = authenticationService.isAuthenticated();
    this.refreshCartCount();
  }

  public toggleMenu() {
    if (document.body.classList.contains("menu-open")) {
      document.body.classList.remove("menu-open");
    } else {
      document.body.classList.add("menu-open");
    }
  }

  public toggleDisplayLang(){
    this.displayLang = !this.displayLang;
  }
}
