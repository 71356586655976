import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route, RouteConfig} from "vue-router";
import authenticationService from "../common/authentication/authentication.service";
import BrandtVue from "../common/brandt-vue";
import i18nService from "../common/i18n.service";
import AlertComponent from "../common/ui/alert.component.vue";
import SpinnerComponent from "../common/ui/spinner.component.vue";
import EndProduct from "../end-product/end-product";
import endProductService from "../end-product/end-product.service";
import "./assistance-shared.scss";
import AssistanceAddToCartComponent from "./step/assistance/assistance-add-to-cart.component.vue";
import AssistanceVisioOrTechnicComponent from "./step/assistance/assistance-choice-visio.component.vue";
import AssistanceChoiceComponent from "./step/assistance/assistance-choice.component.vue";
import AssistanceSearchComponent from "./step/assistance/assistance-search.component.vue";
import AssistanceTimeslotSelectionComponent from "./step/assistance/assistance-timeslot-selection.component.vue";
import assistanceStore from "./step/assistance/assistance.store";
import InterventionTunnelComponent from "./step/intervention/intervention-tunnel.component.vue";
import {AssistanceType} from "./step/assistance-type.enum";
const i18n = i18nService.getI18n();

export const ROUTES = {
    SEARCH: "/search",
    SELECT_VISIO: "/type",
    CHOICE: "/choice",
    TIMESLOT: "/creneaux",
    ADD_TO_CART: "/ajouter",
    INTERVENTION: "/intervention"
};

declare var loginUrl: string;

if (document.querySelector(".AssistancePage")) {
    Vue.use(VueRouter);

    const routes: RouteConfig[] = [
        {
            path: `${ROUTES.SEARCH}`,
            component: AssistanceSearchComponent,
        },
        {
            path: `${ROUTES.SELECT_VISIO}`,
            component: AssistanceVisioOrTechnicComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                next();
            }
        },
        {
            path: `${ROUTES.CHOICE}`,
            component: AssistanceChoiceComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                next();
            }
        },
        {
            path: `${ROUTES.TIMESLOT}`,
            component: AssistanceTimeslotSelectionComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                checkAssistance(to, from, next);
                next();
            }
        },
        {
            path: `${ROUTES.ADD_TO_CART}`,
            component: AssistanceAddToCartComponent,
            beforeEnter: async (to, from, next) => {
                await checkRef(to, from, next);
                checkAssistance(to, from, next);
                next();
            }
        },
        {
            path: `${ROUTES.INTERVENTION}`,
            component: InterventionTunnelComponent,
            beforeEnter: async (to, from, next) => {
                next();
            }
        },
        { path: "*", redirect: `${ROUTES.SEARCH}` }
    ];

    let checkRef = async function checkReferenceQueryParams(to: Route, from: Route, next: Function) {
        // check if the query params contains the value 'reference'
        // and if the store as a defined value for the endproduct
        if (!!to.query.reference && typeof assistanceStore.state.endProduct === "undefined") {
            // if this is the case, we question the Product service to know the if the given reference is valid
            let query = to.query.reference as string;
            let result = await endProductService.findSlug(query);
            // if the given reference is invalid, we redirect to the search page
            if (typeof result === "undefined") {

                next({path: ROUTES.SEARCH});
            // if the given reference is valid, we add it to the store
            } else {
                // place the found product in the store
                let endProduct: EndProduct = result;
                assistanceStore.setEndProduct(endProduct);
            }
        }
    };

    let checkAssistance = function checkAssistanceQueryParams(to: Route, from: Route, next: Function) {
        // check if the query params contains value
        if (!!to.query.assistanceType && typeof assistanceStore.state.assistanceType === "undefined") {
            // if this is the case, we verify if the assistanceType given is valid
            const assistanceType = to.query.assistanceType as string;
            // if the given assistanceType, we redirect to the search page
            if (assistanceType != AssistanceType.DIAGNOSTIC && assistanceType != AssistanceType.TECHNICAL) {

                next({path: ROUTES.SEARCH});
            // if the given assistanceType, we add it to the store
            } else {
                // place the assistanceType in the store
                assistanceStore.setAssistanceType(assistanceType === AssistanceType.DIAGNOSTIC ? AssistanceType.DIAGNOSTIC : AssistanceType.TECHNICAL);
            }
        }
    };

    const router = new VueRouter({
        routes,
        scrollBehavior(to, from, savedPosistion) {
            return {x: 0, y: 0};
        }
    });

    @Component({
        router,
        components: {
            spinner: SpinnerComponent,
            alert: AlertComponent
        }
    })
    class AssistancePage extends BrandtVue {
        public $t: any;
        public links: { string: string };
    }

    const assistancePage = new AssistancePage({ el: ".AssistancePage" });
}
