




















































































































































import Component, {mixins} from "vue-class-component";
import interventionStore from "./intervention.store";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
import zipCodeValidator from "../../../common/validators/zip-code.validator";
import {validationMixin} from "vuelidate";
import {AddressIntervention} from "../../models/address-intervention";
import dqeService, {DqeRnvpResult, DqeStatus} from "../../../common/dqe/dqe.service";
import Address from "../../../account/address";
import DqeRnvpComponent from "../../../common/dqe/dqe-rnvp.component.vue";
import AlertComponent from "../../../common/ui/alert.component.vue";
import radioBtnWarrantyService from "../../services/radio-btn-warranty.service";
import phoneNumberValidator from "../../../common/validators/phone-number.validator";
import {OriginDqeEnum} from "../../../common/dqe/origin-dqe.enum";

declare var privacyPolicyLink: string;

@Component({
    components: {
      "dqe-rnvp": DqeRnvpComponent,
      "alert": AlertComponent
    },
    validations: {
        address: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            line1: {
                required
            },
            zipCode: {
                zipCode: function (value: string) {
                    return zipCodeValidator.validate(value, "FR");
                },
                required,
                numeric,
                zipCodeMinLength: minLength(5),
                zipCodeMaxLength: maxLength(5)
            },
            city: {
                required
            },
            phoneNumber: {
                checkPhoneNumber: function (phoneNumber: string){
                    return phoneNumberValidator.validate(phoneNumber);
                },
                required
            }
        }
    }
}as any)
export default class InterventionAddressDataComponent extends mixins(validationMixin){
    verificationPending: Boolean = false;
    dqeRnvpResult: DqeRnvpResult = null;
    address: Address = null;
    public $v: any;
    public $t: any;
    errorDqe: boolean = false;
    OriginDqeEnum = OriginDqeEnum;
    privacyPolicyLink = privacyPolicyLink;


    created() {
        radioBtnWarrantyService.reviveRadioWarranty();
        this.loadAddress();
    }


    loadAddress() {
        // Init address
        this.address = {
            id: null,
            phoneNumber: '',
            type: null,
            firstName: '',
            lastName: interventionStore.nameContractHolder ?? '',
            line1: '',
            line2: '',
            city: interventionStore.city ?? '',
            countryCode: '',
            zipCode: interventionStore.zipCode ?? '',
            companyName: '',
            default: true
        };

        // Complete form from contract information
        if(interventionStore.customerDetails != null) {
            if (interventionStore.zipCode === interventionStore.customerDetails.address.zipCode) {
                this.address = {
                    id: null,
                    phoneNumber: interventionStore.customerDetails.phoneNumber,
                    type: null,
                    firstName: interventionStore.customerDetails.address.firstName,
                    lastName: interventionStore.customerDetails.address.lastName,
                    line1: interventionStore.customerDetails.address.line1,
                    line2: '',
                    city: interventionStore.customerDetails.address.city,
                    countryCode: interventionStore.customerDetails.address.countryCode,
                    zipCode: interventionStore.customerDetails.address.zipCode,
                    companyName: '',
                    default: true
                };
                this.verifyAddress();
            }
        }

        // autofill when going back
        if(interventionStore.addressIntervention != null) {
            this.address = {
                id: null,
                phoneNumber: interventionStore.addressIntervention.phoneNumber,
                type: null,
                firstName: interventionStore.addressIntervention.firstName,
                lastName: interventionStore.addressIntervention.lastName,
                line1: interventionStore.addressIntervention.line1,
                line2: '',
                city: interventionStore.addressIntervention.city,
                countryCode: interventionStore.addressIntervention.countryCode,
                zipCode: interventionStore.addressIntervention.zipCode,
                companyName: '',
                default: true
            };
            this.verifyAddress();
        }
    }

    async saveInterventionAddress(){
        await this.verifyAddress();
        if(this.errorDqe){
            return;
        }
        let interventionAddress: AddressIntervention = ({
            lastName: this.address.lastName,
            firstName: this.address.firstName,
            line1: this.address.line1,
            zipCode: this.address.zipCode,
            city: this.address.city,
            phoneNumber: this.address.phoneNumber,
            countryCode: this.address.countryCode
        });
        interventionStore.setAddressIntervention(interventionAddress);
        this.$emit('addressCompleted');
        radioBtnWarrantyService.hideRadioWarranty();

    }

    public getErrorMessageKey(validation: any) {
        if (validation.checkPhoneNumber === false){
            return "assistance.formulaire.phoneNumber.error";
        }
        else if (validation.maxLength === false) {
            return "common.error.field-max-length";
        } else if (validation.zipCodeMinLength === false || validation.zipCodeMaxLength === false) {
            return this.$t("common.error.field-invalid-zip-code");
        } else if (validation.minLengthPhoneNumber === false) {
            return this.$t("common.error.field-min-length", [10]);
        }else if (validation.numeric === false) {
            return "common.error.field.numeric";
        }else if (validation.zipCode === false) {
            return "common.error.field-invalid-zip-code";
        } else {
            return "common.error.field-required";
        }
    }

    public async verifyAddress() {
        if (!this.$v.address.line1.$invalid && !this.$v.address.zipCode.$invalid && !this.$v.address.city.$invalid) {
            if(this.address.zipCode != '' && this.address.city != '' && this.address.line1) {
                this.verificationPending = true;
                this.$emit("is-invalid", true);
                this.dqeRnvpResult = await dqeService.checkAddress(this.address);
                if (this.dqeRnvpResult.status === DqeStatus.Warning) {
                    this.applyCorrections(this.dqeRnvpResult.corrections);
                }
                this.$emit(
                    "is-invalid",
                    this.dqeRnvpResult.status !== DqeStatus.Success &&
                    this.dqeRnvpResult.status !== DqeStatus.Warning
                );
                this.verificationPending = false;

                this.errorDqe = this.dqeRnvpResult.status === DqeStatus.Error;
            }
        }
    }

    public applyCorrections(corrections: Address) {
        for (let key in corrections) {
            (this.address as any)[key] = (corrections as any)[key];
        }
    }
    back(){
        this.$emit('back');
    }
}
