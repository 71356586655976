export class DateValidator {

    public dateIsBeforeToday(date: string) {
        if (!!date) {
            const d: string = new Date().toISOString().split("T")[0];
            if (date < d) {
                return true;
            }
        }
        return false;
    }
}

export default new DateValidator();
