import Component, {mixins} from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import BeCalledBackMixin from "../common/be-called-back.mixin";

declare var brandSlug: string;

if (document.querySelector(".BrandLandingPageLayoutPage")) {
    @Component({})
    class BrandLandingPageLayoutPage extends mixins(BrandtVue, BeCalledBackMixin) {
        public brandSlug = brandSlug;

        getLogoRedirectionLink(): string {
            switch(brandSlug) {
                case 'brandt':
                    return 'https://www.brandt.fr/';
                case 'sauter':
                    return 'https://www.sauter-electromenager.com/';
                case 'vedette':
                    return 'https://www.vedette.fr/';
                case 'de-dietrich':
                    return 'https://www.dedietrich-electromenager.fr/';
                default:
                    return 'https://www.brandt.fr/';
            }
        }
    }

    const page = new BrandLandingPageLayoutPage({ el: ".BrandLandingPageLayoutPage" });
}
