
































































































import Vue from "vue";
import Component from "vue-class-component";
import InterventionCheckTypeComponent from "./intervention-check-type.component.vue";
import InterventionStaWarrantyFormComponent from "./intervention-sta-warranty-form.component.vue";
import InterventionStaNoWarrantyFormComponent from "./intervention-sta-no-warranty-form.component.vue";
import InterventionFormComponent from "./intervention-form.component.vue";
import {CreateIntervention} from "../../models/create-intervention";
import {InterventionCreateRequestType} from "./intervention-create-request-type.enum";
import LoginModalComponent from "../../../common/authentication/login-modal.component.vue";
import interventionStore from "./intervention.store";
import loginModalService from "../../../common/authentication/login-modal.service";
import radioBtnWarrantyService from "../../services/radio-btn-warranty.service";

@Component({
    components: {
        'intervention-check-type': InterventionCheckTypeComponent,
        'intervention-sta-warranty-form': InterventionStaWarrantyFormComponent,
        'intervention-sta-no-warranty-form': InterventionStaNoWarrantyFormComponent,
        'intervention-form': InterventionFormComponent,
        'authentication-login-modal': LoginModalComponent
    }
}as any)
export default class InterventionTunnelComponent extends Vue{
    private showLoginModal: boolean = false;

    InterventionCreateRequestType = InterventionCreateRequestType;
    interventionCreateRequestType: InterventionCreateRequestType = interventionStore.interventionCreateRequestType;
    underWarranty: boolean = null; // explicitement a undefined dans le CDC
    hideRadioWarrantySelector: boolean = false;

    data: CreateIntervention = {

    };
    updated(){
        radioBtnWarrantyService.emitWarrantyChange();
        interventionStore.setUnderWarranty(this.underWarranty);
    }

    mounted() {
        loginModalService.$on('loginmodal', this.openModal);
        radioBtnWarrantyService.$on('hideradiowarranty', this.hideRadioWarranty)
        radioBtnWarrantyService.$on('reviveradiowarranty', this.reviveRadioWarranty)
    }
    beforeDestroy() {
        loginModalService.$off('loginmodal', this.openModal);
        radioBtnWarrantyService.$off('hideradiowarranty', this.hideRadioWarranty)
        radioBtnWarrantyService.$off('reviveradiowarranty', this.reviveRadioWarranty)
    }

    openModal(){
        this.showLoginModal = true;
    }

    hideRadioWarranty(){
        this.hideRadioWarrantySelector = true;
    }

    reviveRadioWarranty(){
        this.hideRadioWarrantySelector = false;
    }

    back(){
        this.interventionCreateRequestType = null;
    }
}
