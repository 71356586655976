import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import AutoCompleteReferenceReply from "./auto-complete-reference-reply";
import endProductService from "./end-product.service";

@Component({
  components: {},
  watch: {
    query() {
      return this.debouncedSearchReferences();
    },
  },
})
export default class PartFinderMixin extends Vue {

  public searchPartSubmitting = false;
  public query = "";
  public results: AutoCompleteReferenceReply[] = [];
  public debouncedSearchReferences = _.debounce(this.searchReferences, 250);

  public partFinderSelectNext() {
    const selectedIndex = _.findIndex(this.results, (result) => result.selected);
    const indexToSelect = selectedIndex < 0 ? 0 :
      (selectedIndex + 1 < this.results.length) ? selectedIndex + 1 : this.results.length - 1;
    this.partFinderSelect(indexToSelect);
  }

  public partFinderSelectPrevious() {
    const selectedIndex = _.findIndex(this.results, (result) => result.selected);
    const indexToSelect = selectedIndex < 0 ? 0 :
      (selectedIndex - 1 < this.results.length) ? selectedIndex - 1 : this.results.length - 1;
    this.partFinderSelect(indexToSelect);
  }

  public partFinderFollowLink() {
    const currentlySelected = _.find(this.results, (result) => result.selected);
    window.location.href = currentlySelected.link;
  }

  public partFinderGet() {
    const currentlySelected = _.find(this.results, (result: AutoCompleteReferenceReply) => result.selected);
    return currentlySelected;
  }

  public partFinderSelect(index: number) {
    if (index >= 0 && index < this.results.length) {
      const currentlySelected = _.find(this.results, (result) => result.selected);
      if (currentlySelected != null) {
        currentlySelected.selected = false;
      }
      this.results[index].selected = true;
      this.$forceUpdate();
    }
  }

  public async searchReferences() {
    if (!this.query) {
      return;
    }
    try {
      this.searchPartSubmitting = true;
      this.results = (await endProductService.findReferences(this.query)).content.map((res) => {
        let fullReference = res.commercialReference + "/" + res.technicalReference;

        const regexp = new RegExp("(" + this.query + ")", "gi");
        fullReference = fullReference.replace(regexp, "<b>$1</b>");

        res.label = fullReference;

        return res;
      });
    } catch (error) {

    }
    this.searchPartSubmitting = false;
  }

}
