


















































































































import Component from "vue-class-component";
import accountService from "../account.service";
import {ROUTES} from "../account.page";
import {Route} from "vue-router";
import AlertComponent from "../../common/ui/alert.component.vue";
import {AlertType} from "../../common/ui/alert-type";
import {AccountAssistanceRdv} from "./assistance-rdv.model";
import UtilsService from "../../common/services/utils.service";
import ConfirmationModal from "../../common/ui/confirmation-modal.component.vue";
import TimeslotModalComponent from "../../cart/timeslot-modal.component.vue";
import moment from "moment-timezone";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {AssistanceType} from "../../assistance/step/assistance-type.enum";
import {AssistanceSearchType} from "../../assistance/step/assistance-search.type.enum";
import authenticationService from "../../common/authentication/authentication.service";
import apiClient from "../../common/services/api.client";
import assistanceRdvService from "../../assistance/services/assistance-rdv.service";
import {Prop} from "vue-property-decorator";
import {AccountAssistanceRdvStatus} from "./assistance-account-status";
import Vue from "vue";
import {Filters} from "../../common/filters";
import {SimpleQueryStatus} from "../../common/models/simple-query-status";
import {InterventionType} from "../../assistance/step/intervention/intervention-type.enum";
import {OptitimeReserveStatus} from "./optitime-reserve-status";
import {TimeslotEvent} from "../../common/models/timeslot-event.model";
import {AccountRescheduleAssistanceRequest} from "../account-reschedule-assistance-request";

@Component({
    components: {
        alert: AlertComponent,
        'confirmation-modal': ConfirmationModal,
        'timeslot-modal': TimeslotModalComponent,
        'fa-icon': FontAwesomeIcon,
    },
    filters: {
        'capitalize': Filters.Capitalize
    },
    computed: {
        canAccessRdv(): boolean {
            if (this.assistance.assistanceRdv.cancelled) return false;
            if (this.isIntervention) return false;
            if (!this.startTime || !this.endTime) return false;
            const lowerLimit = this.startTime.clone().subtract(15, "minutes");
            return this.currentMoment.isBetween(lowerLimit, this.endTime);
        },
        canReschedule(): boolean {
            if (this.assistance.assistanceRdv.cancelled) return false;
            if (!this.startTime) return false;
            if(this.isAssistance) {
                const timeLimit = this.startTime.clone().subtract(24, "hours");
                return this.currentMoment.isBefore(timeLimit);
            } else {
                const isReschedulableIntervention = !!this.interventionCancelDeadline && this.currentMoment.isBefore(this.interventionCancelDeadline)
                const isInter3 = this.assistance.assistanceRdv.interventionType === InterventionType.INTER3;
                const isNotConfirmed = this.assistance.assistanceRdv.optiTimeReserveStatus !== OptitimeReserveStatus.CONFIRMED;
                return isReschedulableIntervention && isInter3 && isNotConfirmed;
            }

        },
        canCancel(): boolean {
            if (this.assistance.assistanceRdv.cancelled) return false;
            if (!this.startTime) return false;
            if (this.isAssistance) {
                return this.currentMoment.isBefore(this.startTime);
            } else {
                // Must be 24h before, at most at 16.00
                return !!this.interventionCancelDeadline && this.currentMoment.isBefore(this.interventionCancelDeadline);
            }
        },
        canAccessReport(): boolean {
            if (this.assistance.assistanceRdv.cancelled) return false;
            if (!this.isAssistance) return false;
            if (!this.endTime) return false;
            return this.currentMoment.isAfter(this.endTime);
        },
        isForthcoming(): boolean {
            if (this.assistance.assistanceRdv.cancelled) return false;
            if (!this.startTime) return false;
            return this.currentMoment.isBefore(this.startTime);
        },
        status(): AccountAssistanceRdvStatus {
            if (!this.startTime || !this.endTime || this.assistance.assistanceRdv.cancelled) {
                return AccountAssistanceRdvStatus.CANCELLED;
            } else if (this.currentMoment.isBefore(this.startTime)) {
                return AccountAssistanceRdvStatus.FORTHCOMING;
            } else if (this.currentMoment.isBetween(this.startTime, this.endTime)) {
                return AccountAssistanceRdvStatus.ONGOING;
            } else {
                return AccountAssistanceRdvStatus.DONE;
            }
        }
    },
})
export default class AssistanceCardComponent extends Vue {
    public $route: Route;
    public $t: any
    public AlertType = AlertType;

    @Prop()
    public readonly assistance: AccountAssistanceRdv;
    @Prop()
    public readonly loadingContext: SimpleQueryStatus;

    public readonly isAssistance = this.assistance.assistanceRdv.assistanceType != null;
    public readonly isIntervention = this.assistance.assistanceRdv.interventionType != null;
    public readonly crossSellingLink = `${ROUTES.ASSISTANCE_CROSS_SELLING}/${this.assistance.assistanceRdv.id}`;
    public readonly assistanceId = this.assistance.assistanceRdv.id;

    public confirmCancelRdv: AccountAssistanceRdv | null = null;
    public confirmRescheduleRdv: AccountAssistanceRdv | null = null;
    private rescheduleRdvType: String | null = null;
    public reserve_error: Boolean = false

    private currentMoment: moment.Moment = moment();
    public startTime: moment.Moment | null = null;
    public endTime: moment.Moment | null = null;
    public interventionCancelDeadline: moment.Moment | null = null;

    public dateDisplay = '';
    public startTimeDisplay = '';
    public endTimeDisplay = '';


    public created() {
        this.initDate();
        setInterval(() => {
            this.currentMoment = moment();
        }, 2000);
    }

    initDate() {
        const timeslotStartTime = this.assistance.assistanceRdv.timeSlotStartTime;
        if (!!timeslotStartTime) {
            this.startTime = moment(timeslotStartTime);

            const interCancelDl1 = this.startTime.clone().subtract(24, 'hours');
            const interCancelDl2 = interCancelDl1.clone().set({"hours": 16, "minutes": 0, "seconds": 0});
            this.interventionCancelDeadline = moment.min([interCancelDl1, interCancelDl2]);
        }
        const timeslotEndTime = this.assistance.assistanceRdv.timeSlotEndTime;
        if (!!timeslotEndTime) {
            this.endTime = moment(timeslotEndTime);
        }
        this.dateDisplay = (this.startTime ?? moment(this.assistance.assistanceRdv.updateDate)).local().format("dddd DD MMMM Y") ?? '';
        this.startTimeDisplay = this.startTime?.local().format("HH:mm") ?? '';
        this.endTimeDisplay = this.endTime?.local().format("HH:mm") ?? '';
    }

    public closeModal() {
        this.confirmRescheduleRdv = null;
        this.rescheduleRdvType = null;
        //remove the class to allow scrolling on the page
        document.body.classList.remove("modal-opened");
    }

    public rescheduleButton(assistance: AccountAssistanceRdv) {
        //necessary to block the background from scrolling when the modal is shown
        document.body.classList.add("modal-opened");
        this.confirmRescheduleRdv = assistance;
        if (assistance.assistanceRdv.interventionType){
            this.rescheduleRdvType = this.confirmRescheduleRdv.assistanceRdv.interventionType;
        } else {
            this.rescheduleRdvType = this.confirmRescheduleRdv.assistanceRdv.assistanceType === AssistanceType.DIAGNOSTIC ? AssistanceSearchType.DIAGNOSTIC : AssistanceSearchType.ASSISTANCE;
        }
    }

    public cancelButton(assistance: AccountAssistanceRdv) {
        this.confirmCancelRdv = assistance;
    }

    // choose which message to display in the cancel modal
    public chooseCancelBody() {
        if (!this.confirmCancelRdv) {
            return 'account.assistance-cancel-modal.body';
        }

        if (this.confirmCancelRdv.assistanceRdv.interventionType) {
            return 'account.assistance-cancel-modal.body-intervention';
        } else {
            const limitTime = this.startTime.clone().subtract(24, "hours");
            return this.currentMoment.isBefore(limitTime)
                ? 'account.assistance-cancel-modal.body-before-24h'
                : 'account.assistance-cancel-modal.body';
        }
    }

    public async confirmReschedule(event: TimeslotEvent) {
        let newTimeSlot = event.selectedTimeSlot;
        let rdv = this.confirmRescheduleRdv;
        this.confirmRescheduleRdv = null;
        this.loadingContext.isLoading = true;
        try {
            newTimeSlot = UtilsService.formatTimeslotZoneDateTime(newTimeSlot);

            const dto: AccountRescheduleAssistanceRequest = {
                assistanceId: rdv.assistanceRdv.id,
                timeSlot: newTimeSlot,
                suggestedTimeSlots: event.suggestedTimeSlots
            };
            await accountService.rescheduleAssitance(dto);

        } catch (e) {

            this.reserve_error = true;
            this.loadingContext.isError = true;
        }
        //remove the class to allow scrolling on the page
        document.body.classList.remove("modal-opened");

        this.dateDisplay = moment(newTimeSlot.startTime).local().format("dddd DD MMMM Y");
        this.$emit('assistance-change');
    }

    public async confirmCancel() {
        let rdv = this.confirmCancelRdv;
        this.confirmCancelRdv = null;
        this.loadingContext.isLoading = true;
        try {
            await accountService.cancelAssitance(rdv);
        } catch (e) {

            this.loadingContext.isError = true;
        }

        this.$emit('assistance-change');
    }

    public getReportLink(assistance: AccountAssistanceRdv) {
        const assistanceId = assistance.assistanceRdv.id;
        const accessToken = authenticationService.getToken();
        return apiClient.getApiPath(
            `/account/assistances/${assistanceId}/report?access_token=${accessToken}`,
            '1.0.0'
        );
    }

    public getTypeLabelKey(): string {
        return assistanceRdvService.getTypeLabelKey(this.assistance);
    }
}
