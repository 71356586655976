


















































































import Component from "vue-class-component";
import Vue from "vue";
import interventionStore from "./intervention.store";
import UtilsService from "../../../common/services/utils.service";
import AlertComponent from "../../../common/ui/alert.component.vue";
import interventionRdvService from "../../services/intervention-rdv.service";
import {ReserveInterventionTimeslot} from "../../models/reserve-intervention-timeslot";
import SpinnerComponent from "../../../common/ui/spinner.component.vue";
import {AddressReserveIntervention} from "../../models/address-reserve-intervention";
import authenticationService from "../../../common/authentication/authentication.service";
import loginModalService from "../../../common/authentication/login-modal.service";

@Component({
    components: {
        alert: AlertComponent,
        spinner: SpinnerComponent,
    },
})
export default class InterventionValidateTimeslotComponent extends Vue {
    public submitting = false;
    public submitError: boolean = null;
    public submitSuccess: boolean = null;
    public cgv = false;
    public firstName: string = '';
    public lastName: string = '';
    public date: string = '';
    public city: string = '';
    public zipCode: string = '';
    public line: string = '';
    public fullReference: string = '';
    public isSqueezed: boolean = false;

    created() {
        this.firstName = interventionStore.addressIntervention.firstName;
        this.lastName = interventionStore.addressIntervention.lastName;
        this.date = UtilsService.formatTimeslotDateOnly(interventionStore.selectedTimeslot);
        this.city = interventionStore.addressIntervention.city;
        this.zipCode = interventionStore.addressIntervention.zipCode;
        this.line = interventionStore.addressIntervention.line1;
        this.fullReference = interventionStore.commercialReference + "/" + interventionStore.technicalReference;

        if(!authenticationService.isAuthenticated()){
            loginModalService.emitLoginModal();
            return;
        }
    }

    async reserve() {
        if(!authenticationService.isAuthenticated()){
            loginModalService.emitLoginModal();
            return;
        }
        this.submitError = false;
        this.submitSuccess = false;
        this.isSqueezed = true; // On empêche le client de spam requête
        this.submitting = true;

        let dtoAddressReserveIntervention: AddressReserveIntervention = ({
            firstName: interventionStore.addressIntervention.firstName,
            lastName: interventionStore.addressIntervention.lastName,
            companyName: interventionStore.customerDetails?.address?.companyName ?? null,
            line1: interventionStore.addressIntervention.line1,
            line2: interventionStore.customerDetails?.address?.line2 ?? null,
            zipCode: interventionStore.addressIntervention.zipCode,
            city: interventionStore.addressIntervention.city,
            countryCode: interventionStore.addressIntervention.countryCode ?? "FR",
        });

        const warranty = interventionStore.underWarranty === true ? 1 : 0;

        let dto: ReserveInterventionTimeslot = ({
            reference: interventionStore.referenceUnit,
            contractNumber: interventionStore.contractNumber ?? null,
            purchaseDate: interventionStore.purchaseDate,
            serialNumber: interventionStore.serialNumber ?? null,
            warrantyFFF: interventionStore.purchaseStore ?? null,
            interventionAddress: {
                address: dtoAddressReserveIntervention,
                email: interventionStore.customerDetails?.email ?? "",
                phoneNumber: interventionStore.addressIntervention.phoneNumber
            },
            timeSlot: {
                startDateTime: interventionStore.selectedTimeslot.startTime,
                endDateTime: interventionStore.selectedTimeslot.endTime
            },
            description: interventionStore.description ?? "",
            underWarranty: warranty,
            brandPlanId: interventionStore.brandPlanId ?? null,
            suggestedTimeSlots: interventionStore.suggestedTimeSlots ?? null
        });

        try {
            await interventionRdvService.reserveInterventionTimeSlots(dto);
            this.submitSuccess = true;
        } catch (e) {

            this.submitError = true;
            this.isSqueezed = false;
        } finally {
            this.submitting = false;

        }
    }

    back(){
        this.$emit('back');
    }

}
