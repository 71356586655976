
































import Vue from "vue";
import Component from "vue-class-component";
import VueRouter from "vue-router";
import { Route } from "vue-router";
import assistanceStore from "./assistance.store";
import { ROUTES } from "../../assistance.page";
import AssistanceVisioOrTechnicCardComponent from "./assistance-choice-visio-card.component.vue";

declare var interventionLinks: any;

@Component({
    components: {
        "card": AssistanceVisioOrTechnicCardComponent,
    }
})
export default class AssistanceVisioOrTechnicComponent extends Vue {
    public $router: VueRouter;
    public $route: Route;
    public reference: string;

    public interventionLinks = interventionLinks;

    public assistance: any = {
        title: "assistance.type.assistance.title",
        content: "assistance.type.assistance.content",
        image: 'assistance_distance.jpg',
        type: "assistance",
        encart: ""
    }

    public intervention: any = {
        title: "assistance.type.intervention.title",
        content: "assistance.type.intervention.content",
        image: 'assistance_technicien.jpg',
        type: "intervention",
        encart: "assistance.type.encart"
    }

    created(){
        this.reference = assistanceStore.endProduct.reference;
    }

    redirect(shouldContinueThroughTunnel: boolean, tunnel: string){
        if (shouldContinueThroughTunnel && tunnel) {
            if(tunnel === 'intervention'){
                this.$router.push({path:ROUTES.INTERVENTION, query: this.$route.query});
            } else {
                this.$router.push({path:ROUTES.CHOICE, query: this.$route.query});
            }
        } else {
            window.location.href = this.getLinkByBrand(assistanceStore.state.endProduct.brand);
        }
    }

    private getLinkByBrand(brand: string): string {
        switch (brand) {
            case "DE_DIETRICH": {
                return this.interventionLinks['de-dietrich'];
            }
            case "BRANDT": {
                return this.interventionLinks['brandt'];
            }
            case "SAUTER": {
                return this.interventionLinks['sauter'];
            }
            case "VEDETTE": {
                return this.interventionLinks['vedette'];
            }
            default: {
                return this.interventionLinks['brandt'];
            }
        }
    }
}
