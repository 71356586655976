



































































import Vue from "vue";
import Component from "vue-class-component";
import UtilsHttpService from "../services/utils-http.service";


@Component({
    components: {},
    props: {
        product: {
            type: Object,
            required: true
        },
        netReviewsProductWidgetUrl: String,

    }
})
export default class ProductReviewModalComponent extends Vue {
    public product:any;
    public netReviewsProductWidgetUrl: string;
    public id = '';
    public locale = 'fr_FR';
    public price = 'Product Price';
    public currency = 'Price Currency';
    public name = 'Product Title';
    public url = '#';
    public imageUrl = ' imageUrl';

    async created(){

        this.id = this.product?.id;
        this.locale = this.product?.locale;
        this.price = this.product?.price?.value;
        this.currency = this.product?.price?.currencyCode;
        this.name = this.product?.title;
        //this.url = we don't have the url, todo build it;
        this.imageUrl = this.product?.medias[0]?.fileLink;
        await UtilsHttpService.loadScript(this.netReviewsProductWidgetUrl);

    }

    public onClose() {
        this.$emit("on-productreview-close");
    }

}
