import Component, {mixins} from "vue-class-component";
import BrandtVue from "../common/brandt-vue";
import BeCalledBackMixin from "../common/be-called-back.mixin";
import VueSimpleAccordion from 'vue-simple-accordion';
import Vue from "vue";
import './qualirepar.scss';

declare var staticUrl: string;

if (document.querySelector(".QualireparPage")) {

    Vue.use(VueSimpleAccordion);

    @Component({})
    class QualireparPage extends mixins(BrandtVue, BeCalledBackMixin) {
        public brandComingFrom: string;
        public staticUrl: string = staticUrl;

        initActiveByItem: any = {
            'what-is-qualirepar': false,
            'how-to-benefit': false,
            'applicable-devices': false,
            'offers-by-brand': false,
            'bonus-application': false,
            'bonus-exclusion': false,
        };

        activateTab(id: any) {
            location.hash = `#${id}`;
            location.reload();
        }

        created() {
            this.brandComingFrom = new URLSearchParams(window.location.search).get('brand') || '';
            document.body.className = this.brandComingFrom;
        }

        beforeMount() {
            if (location.hash) {
                const id = location.hash.substr(1);
                this.initActiveByItem[id] = true;
            }
        }

        getPhoneNumber() {
            switch (this.brandComingFrom) {
                case 'dedietrich':
                    return "tel: 0970833434";
                default:
                    return "tel: 0970832525";
            }
        }

        getPhoneNumberImageLink() {
            switch (this.brandComingFrom) {
                case 'dedietrich':
                    return `${this.staticUrl}/brand-landing-page/img/de-dietrich/logo_tel.png`;
                default:
                    return `${this.staticUrl}/brand-landing-page/img/qualirepar/logo_tel.png`;
            }
        }
    }

    const page = new QualireparPage({el: ".QualireparPage"});
}
