




























































































































































































































































































import Component, {mixins} from "vue-class-component";
import AlertComponent from "../../../common/ui/alert.component.vue";
import interventionStore from "./intervention.store";
import interventionService from "../../services/intervention-rdv.service";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";
import PartFinderMixin from "../../../end-product/part-finder.mixin";
import AutoCompleteReferenceReply from "../../../end-product/auto-complete-reference-reply";
import dateValidator from "../../../common/validators/date.validator";
import {WarrantyPublic} from "../../models/warranty-public";
import {FindMaintenanceContract} from "../../models/find-maintenance-contract";
import SpinnerComponent from "../../../common/ui/spinner.component.vue";
import {MaintenanceContractCustomer} from "../../models/maintenance-contract-customer";
import assistanceStore from "../assistance/assistance.store";
import {BrandPlan} from "../../models/brand-plan";
import {Contract} from "../../models/contract";
import moment from "moment-timezone";

declare var privacyPolicyLink: string;
declare var staticUrl: string;


@Component({
    components: {
        'alert': AlertComponent,
        'spinner': SpinnerComponent
    },
    validations: {
        city:{
            required
        },
        query:{
            required
        },
        purchaseDate: {
            purchaseDate: function (value: string){
                return dateValidator.dateIsBeforeToday(value);
            },
            required
        },
        contractNumber:{
            required,
            numeric,
            checkMinLengthContractNumber: minLength(14),
            checkMaxLengthContractNumber: maxLength(14)
        },
        warranty:{
            warranty: function() {
                if (this.underWarrantyForm) {
                    return this.$v.purchaseStore.$invalid === false || this.$v.contractNumber.$invalid === false;
                }else {
                    return true;
                }
            }
        },
        purchaseStore:{
            required: function() {
                return this.underWarrantyForm ? this.purchaseStore != "" : true;
            },
        },
        description:{
            required
        },
        plan:{
            required: function() {
                if (!this.underWarrantyForm){
                    if (this.plan != null){
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            },
        }
    },
    computed: {
        maxDate() {
            const today = new Date();
            today.setDate(today.getDate() - 1);
            return today.toISOString().split('T')[0];
        }
    },
    methods: {
        formatBrandPlanLabel(label: string): string{
            return label.replace(/\*/g, '');
        }
    }
}as any)
export default class InterventionFormDataComponent extends mixins(validationMixin, PartFinderMixin) {
    public $v: any;
    public $t: any;
    submitting = false;
    zipCode: string = '';
    city: string = '';
    purchaseDate: string = '';
    nameContractHolder: string = '';
    serialNumber: string = '';
    contractNumber: string = '';
    contractIsNotFound: boolean = false;
    purchaseStore: string = '';
    plans: BrandPlan[] = null;
    plan: string = '';
    description: string = '';
    warranties: WarrantyPublic[] = null;
    privacyPolicyLink = privacyPolicyLink;
    staticUrl = staticUrl;


    isUnderWarranty: boolean = null;
    customerDetails: MaintenanceContractCustomer;
    selectedEndProduct: AutoCompleteReferenceReply = undefined;

    underWarrantyForm = interventionStore.underWarranty;
    formIsDisabled: boolean = false;
    errorCode: string = '';
    priceListBrandUrl: string = '';


    created() {
        this.$watch('purchaseStore', (newValue, oldValue) => {
            this.formIsDisabled = newValue === 'Autre';
        });

        this.zipCode = interventionStore.zipCode;
        this.loadPurchaseStore();

        if(assistanceStore.endProduct != undefined) {
            this.selectedEndProduct = new AutoCompleteReferenceReply();
            this.selectedEndProduct.reference = assistanceStore.endProduct.reference;
            this.selectedEndProduct.commercialReference = assistanceStore.endProduct.commercialReference;
            this.selectedEndProduct.technicalReference = assistanceStore.endProduct.technicalReference;
            this.query = this.selectedEndProduct.commercialReference + '/' + this.selectedEndProduct.technicalReference;
            this.loadBrandPlan(this.selectedEndProduct.reference);
        }

        this.fillFormOnBackward()
    }

    async loadPurchaseStore() {
        try{
            this.submitting = true;
            this.warranties = await interventionService.getWarrantiesByType("FFF");
        }catch (err){

        }finally {
            this.submitting = false;
        }
    }

    async loadBrandPlan(ref: string) {
        this.plan = null;
        try{
            this.submitting = true;
            this.plans = await interventionService.getPlanByReference(ref);
            if(this.plans.length > 0) {
                this.priceListBrandUrl = this.definePriceListBrandUrl(this.plans[0].brandId);
            }
        }catch (err){

        }finally {
            this.submitting = false;
        }
    }

    async next(){
        this.$v.$touch()
        if(!this.nextEnable()){

            return;
        }

        if(!!this.contractNumber) {
            await this.contractIsGuaranteed();
            if (this.isUnderWarranty) {
                this.saveStore();
                this.$emit('chooseRdvClicked');
            }
        }
        else {
            this.saveStore();
            this.$emit('chooseRdvClicked');
        }

    }

    saveStore(){
        interventionStore.setCity(this.city);
        interventionStore.setPurchaseDate(new Date(this.purchaseDate));
        interventionStore.setReferenceUnit(this.selectedEndProduct.reference);
        interventionStore.setNameContractHolder(this.nameContractHolder);
        interventionStore.setSerialNumber(this.serialNumber);
        interventionStore.setCommercialReference(this.selectedEndProduct.commercialReference);
        interventionStore.setTechnicalReference(this.selectedEndProduct.technicalReference);
        interventionStore.setDescription(this.description);
        interventionStore.setUnderWarranty(this.underWarrantyForm);

        if(this.underWarrantyForm){
            if(!!this.contractNumber) {
                interventionStore.setContractNumber(this.contractNumber);
                interventionStore.setCustomerDetails(this.customerDetails);
            } else {
                interventionStore.setPurchaseStore(this.purchaseStore);
            }
        } else {
            interventionStore.setBrandPlanId(this.plan);
        }
    }

    fillFormOnBackward(){
        if(!!interventionStore.description){
            this.selectedEndProduct = new AutoCompleteReferenceReply();
            this.selectedEndProduct.reference = interventionStore.referenceUnit;
            this.selectedEndProduct.commercialReference = interventionStore.commercialReference;
            this.selectedEndProduct.technicalReference = interventionStore.technicalReference;
            this.query = this.selectedEndProduct.commercialReference + '/' + this.selectedEndProduct.technicalReference;
            this.loadBrandPlan(this.selectedEndProduct.reference);

            this.city = interventionStore.city;
            this.purchaseDate = this.formatDate(interventionStore.purchaseDate);
            this.nameContractHolder = interventionStore.nameContractHolder;
            this.serialNumber = interventionStore.serialNumber;
            this.description = interventionStore.description;
            this.underWarrantyForm = interventionStore.underWarranty;

            if(this.underWarrantyForm){
                if(!!interventionStore.contractNumber) {
                    this.contractNumber = interventionStore.contractNumber;
                    this.customerDetails = interventionStore.customerDetails;
                } else {
                    this.purchaseStore = interventionStore.purchaseStore;
                }
            } else {
                this.plan = interventionStore.brandPlanId;
            }
        }

    }
    async contractIsGuaranteed(){
        this.submitting = true;

        this.isUnderWarranty = false;
        this.customerDetails = null;
        let customerDetails: MaintenanceContractCustomer = null;

        let dto: FindMaintenanceContract = ({
            contractNumber: this.contractNumber,
            purchaseStore: this.purchaseStore,
            purchaseDate: new Date(this.purchaseDate),
            commercialReference: this.selectedEndProduct.commercialReference,
            technicalReference: this.selectedEndProduct.technicalReference,
            reference: this.selectedEndProduct.reference,
            serialNumber: this.serialNumber
        });

        await interventionService.contractIsGuaranteed(dto)
            .then(response => {
                customerDetails = response;
            })
            .catch(error => {
                if (error.response) {
                    this.errorCode = error.response.data.code;
                }
            }).finally(() => {
                this.submitting = false;
            });

        if(customerDetails !!= null){
            this.isUnderWarranty = true;
            this.customerDetails = customerDetails;
        }
    }

    async autoCompleteContract(){
        if (!(!!this.selectedEndProduct?.commercialReference && !!this.nameContractHolder)){
            return;
        }

        const findContractDto: FindContract = ({
            commercialReference: this.selectedEndProduct.commercialReference,
            contractHolderName: this.nameContractHolder
        });
        const contractResponse: Contract = await interventionService.findContractByCommercialRefAndNameHolder(findContractDto);

        if(contractResponse?.contractNumber && contractResponse?.contractStartDate){
            this.contractNumber = contractResponse.contractNumber;
            this.purchaseDate = this.formatDate(contractResponse.contractStartDate);
        }else {
            this.contractIsNotFound = true;
        }
    }

    selectReferenceUnit(valueSelected: AutoCompleteReferenceReply){
        this.selectedEndProduct = valueSelected;
        this.query = valueSelected.commercialReference + '/' + valueSelected.technicalReference;
        this.underWarrantyForm ? this.autoCompleteContract() : this.loadBrandPlan(valueSelected.reference);
    }

    nextEnable() {
        if (this.underWarrantyForm){
            return this.$v.city.$invalid === false &&
                this.$v.query.$invalid === false &&
                this.$v.purchaseDate.$invalid === false &&
                this.$v.warranty.$invalid === false &&
                this.$v.description.$invalid === false;

        }else {
            return this.$v.city.$invalid === false &&
                this.$v.query.$invalid === false &&
                this.$v.purchaseDate.$invalid === false &&
                this.$v.plan.$invalid === false &&
                this.$v.description.$invalid === false;
        }
    }
    public getErrorMessageKey(validation: any) {
        if (validation.purchaseDate === false) {
            return "common.error.field-date-is-not-before-today";
        } else if (validation.numeric === false) {
            return "common.error.field.numeric";
        } else if (validation.checkMinLengthContractNumber === false || validation.checkMaxLengthContractNumber === false) {
            return "intervention.error.contract-number.length"
        } else if (validation.required) {
            return "common.error.field-required";
        } else {
            return "common.error.field-required";
        }
    }

    private formatDate(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    refreshWarranty(){
        this.contractIsNotFound = false;
        this.isUnderWarranty = null;
    }

    back(){
        this.$emit('back');
        interventionStore.resetState();
    }

    handleErrorCode(errorCode: string): string {
        let message;
        switch (errorCode) {
            case 'MAINTENANCE_CONTRACT_NOT_FOUND':
                message = this.$t("intervention.product.is-not-on-warranty.wrong-contract");
                break;
            case 'MAINTENANCE_CONTRACT_PRODUCT_NOT_FOUND':
                message = this.$t("intervention.product.is-not-on-warranty.wrong-product");
                break;
            case 'MAINTENANCE_CONTRACT_EXPIRE':
                message = this.$t("intervention.product.is-not-on-warranty.default");
                break;
            default:
                message = this.$t("intervention.product.is-not-on-warranty.default");
        }
        return message;
    }

    private definePriceListBrandUrl(brandId: string): string {
        const allowedBrandIds = ['BRANDT', 'DE_DIETRICH', 'SAUTER', 'VEDETTE'];
        const defaultBrandId = 'brandt';

        // need to replace all '_' by '-' to have the same format as the brandId and make it lowercase
        const brandIdFormatted = allowedBrandIds.includes(brandId.toUpperCase())
            ? brandId.replace(/_/g, '-').toLowerCase()
            : defaultBrandId;

        return `/sav-${brandIdFormatted}/tarifs/`;
    }
}
