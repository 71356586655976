import orderStore, {RelayPointStep} from "./order.store";
import VueRouter from "vue-router";
import Vue from "vue";

export class ValidateStepService extends Vue {
    public $router: VueRouter;
    public state = orderStore.state;

    public async validateStep(step: RelayPointStep){
        const isRelay = await orderStore.isRelayPointMode();

        if(isRelay){
            if(step !== this.state.stepRelayPoint) {
                return false;
            }
        }
        return true;
    }
}
export default new ValidateStepService();
