import { render, staticRenderFns } from "./summary-relay-point.component.vue?vue&type=template&id=45d5b59d&scoped=true&"
import script from "./summary-relay-point.component.vue?vue&type=script&lang=ts&"
export * from "./summary-relay-point.component.vue?vue&type=script&lang=ts&"
import style0 from "./summary-relay-point.component.vue?vue&type=style&index=0&id=45d5b59d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d5b59d",
  null
  
)

component.options.__file = "summary-relay-point.component.vue"
export default component.exports