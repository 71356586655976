import Vue from "vue";
import Component from "vue-class-component";
import VueRouter, {Route, RouteConfig} from "vue-router";
import authenticationService from "../../common/authentication/authentication.service";
import BrandtVue from "../../common/brandt-vue";
import i18nService from "../../common/i18n.service";
import AlertComponent from "../../common/ui/alert.component.vue";
import SpinnerComponent from "../../common/ui/spinner.component.vue";
import OrderPaymentComponent from "./order-payment.component.vue";
import OrderPreferenceComponent from "./order-preference.component.vue";
import orderStore, {RelayPointStep} from "./order.store";
import QuoteTunnelComponent from "./quote-tunnel.component.vue";
import RelayPointComponent from "./relay-point.component.vue";
import SummaryRelayPointComponent from "./summary-relay-point.component.vue";
import {ShippingCode} from "../../common/ui/shipping-code";
import validateStepService from "./validate-step.service";

const i18n = i18nService.getI18n();

export const PATHS = {
    STEP: i18n.t("order.paths.step") as string,
    PREFERENCES: i18n.t("order.paths.preferences") as string,
    PAYMENT: i18n.t("order.paths.payment") as string,
    RELAY_POINT: i18n.t("order.paths.relaypoint") as string,
    SUMMARY_RELAY_POINT: i18n.t("order.paths.summary") as string
};

export const ROUTES = {
    PREFERENCES: `/${PATHS.STEP}/${PATHS.PREFERENCES}`,
    PAYMENT: `/${PATHS.STEP}/${PATHS.PAYMENT}`
};

if (document.querySelector(".OrderPage")) {
    Vue.use(VueRouter);

    const routes: RouteConfig[] = [
        {
            path: `/${PATHS.STEP}`, component: QuoteTunnelComponent,
                props: route => ({
                    cart: orderStore.state.cart
                }), children: [
                { path: PATHS.PREFERENCES, component: OrderPreferenceComponent,

                    beforeEnter: async (to: Route, from: Route, next: any) => {

                    const isNotRP = orderStore.state.cart.shippingCode === ShippingCode.STANDARD;
                    const isConfirmation = await validateStepService.validateStep(RelayPointStep.CONFIRMATION);

                    if (isNotRP) {
                        next();
                    }
                    if (isConfirmation) {
                        next();
                    } else {
                        next(`/${PATHS.RELAY_POINT}`);
                    }
                }},
                {
                    path: PATHS.PAYMENT,
                    component: OrderPaymentComponent,
                    beforeEnter: (to: Route, from: Route, next: any) => {

                        if (!orderStore.state.termsAndConditionsAccepted
                            || orderStore.state.paymentMethods.length === 0) {
                            const newRoute: any = { path: ROUTES.PREFERENCES };
                            if (to.query.orderId) {
                                newRoute.query = { orderId: to.query.orderId };
                            } else if (from.query.orderId) {
                                newRoute.query = { orderId: from.query.orderId };
                            }
                            next(newRoute);
                        } else {
                            next();
                        }
                    }
                }
            ]
        },
        {
            path: `/${PATHS.SUMMARY_RELAY_POINT}`, component: SummaryRelayPointComponent,
            beforeEnter: async (to: Route, from: Route, next: any) => {
                const isNotRP = orderStore.state.cart.shippingCode === ShippingCode.STANDARD;
                const isSummary = await validateStepService.validateStep(RelayPointStep.SUMMARY);

                if (isNotRP) {
                    next(`/${PATHS.STEP}/${PATHS.PREFERENCES}`);
                }
                else if (isSummary) {
                    next();
                } else {
                    next(`/${PATHS.RELAY_POINT}`);
                }
            }
        },
        {
            path: `/${PATHS.RELAY_POINT}`, component: RelayPointComponent,
            beforeEnter: (to: Route, from: Route, next: any) => {
                if (orderStore.state.cart.shippingCode === ShippingCode.STANDARD || !validateStepService.validateStep(RelayPointStep.RP)) {
                    next(`/*`);
                }else {
                    next();
                }
            }
        },

        { path: `/${PATHS.STEP}*`, redirect: `/${PATHS.STEP}/${PATHS.PREFERENCES}` },
        { path: "*",
            beforeEnter: (to: Route, from: Route, next: any) => {
                orderStore.getCart().then(() => {
                    if (orderStore.state.cart.shippingCode === ShippingCode.RELAY_POINT_COLISSIMO || orderStore.state.cart.shippingCode === ShippingCode.RELAY_POINT_COLISSIMO_CONSIGNE) {
                        switch (orderStore.state.stepRelayPoint) {
                            case RelayPointStep.SUMMARY:
                                next(`/${PATHS.SUMMARY_RELAY_POINT}`);
                                break;
                            default:
                                next(`/${PATHS.RELAY_POINT}`);
                                break;
                        }
                    } else {
                        next(`/${PATHS.STEP}/${PATHS.PREFERENCES}`);
                    }
                }).catch((error) => {

                    next(`/${PATHS.STEP}/${PATHS.PREFERENCES}`);
                });
            }
        }
    ];

    const router = new VueRouter({
        routes,
    });

    @Component({
        router,
        components: {
            spinner: SpinnerComponent,
            alert: AlertComponent,
        }
    })
    class OrderPage extends BrandtVue {

        public $t: any;
        public state = orderStore.state;

        public menuRoutes = {
            preferences: ROUTES.PREFERENCES,
            payment: ROUTES.PAYMENT
        };

        public links: { string: string };

        public created() {
            (async () => {
                if (authenticationService.isAuthenticated()) {
                    const promises = [
                        orderStore.getCountries(),
                        orderStore.getAddresses(),
                        orderStore.getCart()
                    ];
                    await Promise.all(promises);
                }
            })();
        }

    }

    const orderPage = new OrderPage({ el: ".OrderPage" });
}
